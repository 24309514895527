import React from 'react';
import PropTypes from 'prop-types';
import T from '@tunz/translation';

import style from './FormProgress.module.scss';
import BadgeIndicator from '../BadgeIndicator/BadgeIndicator';

const FormProgress = ({ steps, currentStep, errorSteps, goToStep }) => (
	<div className={`${style.row} form-progress-row`}>
		{steps.map((s, i) => (
			// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
			<div
				key={s}
				className={
					currentStep === i ? `${style.active} active` : `${style.inactive} inactive`
				}
				onClick={() => currentStep !== i && goToStep(i)}
				role={currentStep !== i ? 'button' : undefined}
			>
				<T>{s}</T>
				<span className={style.numberBullet}>
					{errorSteps[steps[i]] && <BadgeIndicator value={errorSteps[steps[i]]} />}
					{i + 1}
				</span>
			</div>
		))}
	</div>
);

FormProgress.propTypes = {
	currentStep: PropTypes.number.isRequired,
	steps: PropTypes.arrayOf(PropTypes.string).isRequired,
	errorSteps: PropTypes.shape({}),
	goToStep: PropTypes.func,
};

FormProgress.defaultProps = {
	errorSteps: {},
	goToStep: () => undefined,
};

export default FormProgress;
