import shortId from 'shortid';

class UploadFileError extends Error {
	constructor(message, code, values) {
		super();
		this.id = shortId();
		this.message = message;
		this.code = code;
		this.values = values;
	}
}

export const fileSizeExceededError = (fileName, fileSize) =>
	new UploadFileError(
		'File "{fileName}" exceeds maximum file size of {fileSize}',
		'ERROR_LOCAL_FILE_EXCEEDS_MAX_SIZE',
		{
			fileName,
			fileSize,
		},
	);

export const fileTypeNotAcceptedError = (fileName, acceptedTypes) =>
	new UploadFileError(
		'File "{fileName}" is not of an acceptable type ({acceptedTypes})',
		'ERROR_LOCAL_FILE_TYPE_NOT_ACCEPTED',
		{
			fileName,
			acceptedTypes,
		},
	);

export const fileAlreadyAddedError = fileName =>
	new UploadFileError(
		'File "{fileName}" has already been added',
		'ERROR_LOCAL_FILE_ALREADY_ADDED',
		{ fileName },
	);

export const fileNumberExceededError = maxNumberOfFiles =>
	new UploadFileError(
		'You can only provide a maximum of {maxNumberOfFiles} files',
		'ERROR_LOCAL_FILE_NUMBER_EXCEEDED',
		{ maxNumberOfFiles },
	);

export const fileUnreadableError = fileName =>
	new UploadFileError('Problem reading the file {fileName}', 'ERROR_LOCAL_FILE_UNREADABLE', {
		fileName,
	});
