import ValidationErrors from '../AnswerHandler/ValidationErrors';
import TunzError from '../api/TunzError';

function getBool(answer) {
	if (answer === undefined) {
		return false;
	}

	if (typeof answer.value === 'boolean') {
		return answer.value;
	}

	if (typeof answer.value === 'string') {
		return answer.value.toLowerCase() === 'true';
	}

	return false;
}

function extractRange(range) {
	if (range === undefined) {
		return {
			min: '',
			max: '',
		};
	}

	const r = range.substr(1, range.length).substr(0, range.length - 2);
	const arr = r.split(',');
	return {
		min: arr[0],
		max: arr[1],
	};
}

function extractIntegerRange(range) {
	const extractedRange = extractRange(range);
	const min = parseInt(extractedRange.min, 10);
	const max = parseInt(extractedRange.max, 10);
	return {
		min: !Number.isNaN(min) ? min : 0,
		max: !Number.isNaN(max) ? max : null,
	};
}

function extractMultiObjectInnerKeys(constraint) {
	const innerKeys = Object.keys(constraint).filter(e => e !== 'type'); // type define the multi object itself...
	if (innerKeys.length < 1) {
		throw TunzError.fromValidationError(ValidationErrors.EMPTY_MULTI_OBJECT_CONSTRAINT, {
			type: constraint.type,
			rootCause: 'missing key in constraint',
		});
	}
	return innerKeys;
}

function extractRangeFromMultiObject(constraint) {
	if (constraint === undefined) {
		return {
			min: 0,
			max: null,
		};
	}

	const innerKeys = extractMultiObjectInnerKeys(constraint);
	const range = constraint[innerKeys[0]].length;

	return extractIntegerRange(range);
}

const arrayToObject = array =>
	array.reduce(
		(aggregator, current) => ({
			...aggregator,
			[current]: current,
		}),
		{},
	);

export default {
	getBool,
	extractRange,
	extractIntegerRange,
	extractMultiObjectInnerKeys,
	extractRangeFromMultiObject,
	arrayToObject,
};
