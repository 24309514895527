import _ from 'lodash';

const findDeep = (obj, predicate, path = '') => {
	if (typeof obj !== 'object' || obj === null) {
		/* typeof null === 'object' !! */ return undefined;
	}

	const result = [];

	if (predicate(obj)) {
		result.push({
			path,
			value: obj,
		});
	}

	const items = Object.keys(obj)
		.map(sub => findDeep(obj[sub], predicate, `${path}/${sub}`))
		.filter(o => o !== undefined);
	if (items.length > 0) {
		result.push(items);
	}
	return _.flattenDeep(result);
};

const errorsByPage = answers =>
	findDeep(answers, o => o.valid === false).reduce((accumulator, current) => {
		let matches = current.path.match(/\/(\w+?)\//g);
		if (matches == null) {
			matches = current.path.match(/\/\w+/g);
		}
		const pageName = matches[0].replace(/\//g, '');
		if (accumulator[pageName] === undefined) {
			accumulator[pageName] = 1;
		} else {
			accumulator[pageName] += 1;
		}
		return accumulator;
	}, {});

const numberOfErrors = (answers, pathPart = '') =>
	findDeep(answers, o => o.valid === false).filter(o => o.path.includes(pathPart)).length;

export default {
	findDeep,
	errorsByPage,
	numberOfErrors,
};
