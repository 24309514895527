import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import T from '@tunz/translation';
import { displayError as ErrorDisplayer, ToastContainer } from '@tunz/message-displayer';
import '@tunz/message-displayer/dist/index.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-toastify/dist/ReactToastify.css';

import FormWizard, {
	ButtonList,
	Next,
	Previous,
	Step,
	StepList,
	StepProgress,
	Submit,
} from '../../components/FormWizard/FormWizard';
import Actions from '../../actions/answerActions';
import Page from '../../components/Page';
import FormProgress from '../../components/FormProgress';
import style from './RequirementForm.module.scss';
import Flow from '../../components/Flow';
import KycChecklist from '../../components/KycChecklist';
import AnswerUtils from '../../utils/AnswerUtils';
import NoRequiredActionNotification from './NoRequiredActionNotification';
import MetadataUtils from '../../utils/MetadataUtils';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import TmidVerify from '../../components/TmidVerify';
import ConfigurationActions from '../../actions/configurationActions';

export const RequirementForm = props => {
	const buildPage = (keyId, pageProps) => () => <Page keyId={keyId} {...pageProps} />;

	const {
		pages,
		finish,
		pageChanged,
		errorMessage,
		name,
		boardingSteps,
		currentBoardingStep,
		answers,
		isSaving,
		isLoading,
		config,
		getTmidVerified,
	} = props;

	if (errorMessage) {
		ErrorDisplayer(errorMessage);
	}

	if (!isLoading && !errorMessage && !getTmidVerified()) {
		return (
			<Container>
				<Row className="show-Container">
					<Col md={12}>
						<div
							className={`container-fluid bg-light text-dark p-5 ${style.welcomeArea}`}
						>
							<div className="container bg-light p-5">
								<TmidVerify
									keyId={config['merchant.tmid']}
									name="VERIFY_TMID_TITLE"
									tmid={config['merchant.tmid']}
								/>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		);
	}
	return (
		<Container>
			<Row className="show-Container">
				<ToastContainer
					autoClose={10000}
					position="top-right"
					hideProgressBar={false}
					closeOnClick
					pauseOnHover
					pauseOnFocusLoss
					draggable={false}
					draggablePercent={0}
					newestOnTop
				/>
				<Col md={3}>
					<div className={style.requirementForm}>
						<Flow steps={boardingSteps} currentStep={currentBoardingStep} />
						<KycChecklist />
					</div>
				</Col>
				<Col md={9}>
					<div className={style.requirementForm}>
						{Object.entries(pages).length > 0 ? (
							<FormWizard onSubmit={finish} onStepChange={pageChanged}>
								<h1 className={`${style.requirementTitle} requirement-title`}>
									<T>{name}</T>
								</h1>
								<StepProgress>
									{(steps, currentStep, goToStep) => (
										<FormProgress
											currentStep={currentStep}
											steps={steps}
											errorSteps={AnswerUtils.errorsByPage(answers)}
											goToStep={goToStep}
										/>
									)}
								</StepProgress>
								<StepList>
									{Object.values(pages)
										.sort(MetadataUtils.positionComparator)
										.map(p => (
											<Step key={p.key} render={buildPage(p.key, p)} />
										))}
								</StepList>
								<ButtonList className={style.buttonList}>
									<Previous waitWhileProcessing={isSaving || isLoading} />
									{isSaving ? (
										<LoadingIndicator translationCode="SAVING_IN_PROGRESS" />
									) : isLoading ? (
										<LoadingIndicator translationCode="LOADING_IN_PROGRESS" />
									) : null}
									<Submit waitWhileProcessing={isSaving || isLoading} />
									<Next waitWhileProcessing={isSaving || isLoading} />
								</ButtonList>
							</FormWizard>
						) : (
							<NoRequiredActionNotification name={name} config={config} />
						)}
					</div>
				</Col>
			</Row>
		</Container>
	);
};

RequirementForm.propTypes = {
	pages: PropTypes.objectOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
		}),
	),
	answers: PropTypes.shape({}),
	name: PropTypes.string,
	errorMessage: PropTypes.string,
	finish: PropTypes.func,
	pageChanged: PropTypes.func,
	boardingSteps: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
		}),
	),
	currentBoardingStep: PropTypes.number,
	isSaving: PropTypes.bool,
	isLoading: PropTypes.bool,
	config: PropTypes.shape(),
	getTmidVerified: PropTypes.func.isRequired,
};

RequirementForm.defaultProps = {
	pages: {},
	answers: {},
	finish: () => undefined,
	pageChanged: () => undefined,
	errorMessage: undefined,
	name: '',
	boardingSteps: [],
	currentBoardingStep: 0,
	isSaving: false,
	isLoading: false,
	config: {},
};

RequirementForm.displayName = 'RequirementForm';

const mapStateToProps = state => ({
	pages: state.application.pages || {},
	answers: state.answers,
	errorMessage: state.answers.errorMessage,
	name: state.application.boardingFlow.steps[state.application.boardingFlow.currentStep].name,
	boardingSteps: state.application.boardingFlow.steps,
	currentBoardingStep: state.application.boardingFlow.currentStep,
	isSaving: state.application.isSaving,
	isLoading: state.application.isLoading,
	config: state.config,
});

const mapDispatchToProps = dispatch => ({
	finish: () => dispatch(Actions.saveCompleteMerchant()),
	pageChanged: previousPage => dispatch(Actions.savePage(previousPage)),
	getTmidVerified: () => dispatch(ConfigurationActions.getTmidVerified()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequirementForm);
