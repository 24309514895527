import BaseAnswerHandler from './BaseAnswerHandler';
import Errors from './ValidationErrors';

export default class CheckboxAnswerHandler extends BaseAnswerHandler {
	validate(answer, initialAnswer) {
		if (initialAnswer) {
			this.initialState = initialAnswer;
		}
		const { value } = answer;
		let valid = true;
		let error = null;
		let errorValues = null;
		const mandatory = this.field.requirementLevel === 'REQUIRED';

		if (mandatory && value.length === 0) {
			valid = false;
			error = Errors.MANDATORY_FIELD;
		}
		value.forEach(v => {
			if (!this.field.constraint.values.includes(v)) {
				valid = false;
				error = Errors.NOT_VALID;
				errorValues = { value: v };
			}
		});
		return {
			value,
			valid,
			...error,
			errorValues,
			...this.validateAgainstInitialState(answer),
		};
	}

	createAnswer(value) {
		const values = [];
		if (this.field.constraint.values.includes(value)) {
			values.push(value);
		}
		return { value: values };
	}
}
