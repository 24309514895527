import * as BoardingApi from '../api/BoardingApi';
import AnswerHandler from '../AnswerHandler';
import ApplicationActions from './applicationActions';
import ServerErrors from '../api/ServerErrors';
import MetadataUtils from '../utils/MetadataUtils';

export const RECORD_ANSWER = 'RECORD_ANSWER';
export const CLEAR_PAGE_ERRORS = 'CLEAR_PAGE_ERRORS';
export const PERSIST_ANSWER = 'PERSIST_ANSWER';
export const PERSIST_ANSWER_COMPLETE = 'PERSIST_ANSWER_COMPLETE';
export const PERSIST_ANSWER_ERROR = 'PERSIST_ANSWER_ERROR';
export const ANSWER_VALIDATION_ERROR = 'ANSWER_VALIDATION_ERROR';
export const READ_OK_RESPONSE = 'READ_OK_RESPONSE';

const requestQueues = {};
let responseErrors = {};

const findFieldInStore = (store, pageKey, dataGroupId, sectionKey, fieldKey) => {
	let foundPage;
	let foundDataGroup;
	let foundSection;
	let foundField;

	MetadataUtils.onEachField(store.application, (field, section, dataGroup, page) => {
		if (
			page.key === pageKey &&
			dataGroup.id === dataGroupId &&
			section.key === sectionKey &&
			field.key === fieldKey
		) {
			foundPage = page;
			foundDataGroup = dataGroup;
			foundSection = section;
			foundField = field;
		}
	});
	return {
		page: foundPage,
		dataGroup: foundDataGroup,
		section: foundSection,
		field: foundField,
	};
};

const findClosestEndpoint = (field, section, dataGroup, page) => {
	if (field.endpoint) {
		return {
			endpoint: field.endpoint,
			path: `${page.key}/${dataGroup.id}/${section.key}/${field.key}`,
		};
	}

	if (section.endpoint) {
		return {
			endpoint: section.endpoint,
			path: `${page.key}/${dataGroup.id}/${section.key}`,
		};
	}

	if (dataGroup.endpoint) {
		return {
			endpoint: dataGroup.endpoint,
			path: `${page.key}/${dataGroup.id}`,
		};
	}

	if (page.endpoint) {
		return {
			endpoint: page.endpoint,
			path: `${page.key}}`,
		};
	}

	return undefined;
};

const findPersistenceInfo = (store, pageKey, dataGroupId, sectionKey, fieldKey) => {
	const storeInfo = findFieldInStore(store, pageKey, dataGroupId, sectionKey, fieldKey);
	if (storeInfo.field) {
		const endpointInfo = findClosestEndpoint(
			storeInfo.field,
			storeInfo.section,
			storeInfo.dataGroup,
			storeInfo.page,
		);

		if (endpointInfo === undefined) {
			console.error('Unable to find an endpoint to send data to');
			return undefined;
		}

		return {
			field: storeInfo.field,
			endpoint: endpointInfo.endpoint,
			path: endpointInfo.path,
		};
	}
	return undefined;
};

const getDataToSave = (pages = {}, answers = {}) => {
	const newDataToSave = {};
	MetadataUtils.onEachField({ pages }, (field, section, dataGroup, page) => {
		const endpointInfo = findClosestEndpoint(field, section, dataGroup, page);
		if (endpointInfo) {
			const { endpoint, path } = endpointInfo;
			const endpointKey = `${endpoint.method}|${endpoint.url}|${path}`;
			const answer = answers[page.key][dataGroup.id][section.key][field.key];

			if (answer && AnswerHandler.isValid(answer, field)) {
				if (dataGroup.submitArrayData) {
					const arrayDataObject = newDataToSave[endpointKey] || {};
					const arrayData = arrayDataObject[dataGroup.arrayName] || [];

					let fieldObject = arrayData.find(f => f.key === section.key);
					if (fieldObject === undefined) {
						fieldObject = { key: section.key };
						arrayData.push(fieldObject);
					}
					fieldObject[field.key] = AnswerHandler.getDataToSave(answer, field);

					arrayDataObject[dataGroup.arrayName] = arrayData;
					newDataToSave[endpointKey] = arrayDataObject;
				} else {
					newDataToSave[endpointKey] = {
						...newDataToSave[endpointKey],
						[field.key]: AnswerHandler.getDataToSave(answer, field),
					};
				}
			}
		}
	});

	return newDataToSave;
};

const isEntireFormValid = (pages, answers) => {
	let isFormValid = true;

	Object.keys(pages).forEach(pageKey => {
		const page = pages[pageKey];
		const pageAnswers = answers[pageKey];
		if (page && page.dataGroups) {
			page.dataGroups.forEach(group => {
				const dgId = group.id;
				if (group.sections) {
					group.sections.forEach(section => {
						const sectionAnswers = pageAnswers[dgId] && pageAnswers[dgId][section.key];
						if (section.fields) {
							section.fields.forEach(field => {
								if (field.requirementLevel !== 'HIDDEN') {
									const answer =
										sectionAnswers[field.key] ||
										AnswerHandler.createAnswer('', field);
									sectionAnswers[field.key] = AnswerHandler.validate(
										answer,
										field,
									);
									if (!AnswerHandler.isValid(sectionAnswers[field.key], field)) {
										isFormValid = false;
									}
								}
							});
						}
					});
				}
			});
		}
	});

	return isFormValid;
};

const recordAnswer = (page, dataGroup, section, field, answer, mustValidate = false) => (
	dispatch,
	getState,
) =>
	dispatch({
		type: RECORD_ANSWER,
		payload: {
			page,
			dataGroup,
			section,
			field,
			answer,
			mustValidate,
			fieldInfo: mustValidate
				? findFieldInStore(getState(), page, dataGroup, section, field).field
				: null,
		},
	});

const clearPageErrors = () => ({ type: CLEAR_PAGE_ERRORS });

const startPersistAnswer = () => ({ type: PERSIST_ANSWER });

const persistAnswerSuccess = () => ({ type: PERSIST_ANSWER_COMPLETE });

const persistAnswerError = (error, path) => dispatch => {
	// Validation Error
	if (error.errorCode === ServerErrors.SERVER_SIDE_VALIDATION_ERROR) {
		const { details } = error;
		details.forEach(d => {
			dispatch({
				type: ANSWER_VALIDATION_ERROR,
				payload: { errorDetail: d, path },
			});
		});
	}

	dispatch({
		type: PERSIST_ANSWER_ERROR,
		payload: error.message,
	});

	setTimeout(() => dispatch(persistAnswerSuccess()), 100); // To prevent the error from appearing at each render
};

const queueRequest = (endpointKey, request) => {
	const queue = requestQueues[endpointKey] || [];
	queue.push(request);
	requestQueues[endpointKey] = queue;

	const index = queue.indexOf(request);
	// if this request is the first one in the queue, send it now;
	// otherwise wait until the response returned for the previous request.
	if (index !== 0) {
		return;
	}
	request();
};

const sendNextRequestInQueue = endpointKey => {
	const queue = requestQueues[endpointKey];
	// remove the request that has just got a response
	queue.shift();
	// are there still requests in the queue?
	if (queue.length > 0) {
		// keep only the last one, as it contains also the changes in the requests before it.
		queue.splice(0, queue.length - 1);
		queue[0]();
		return true;
	}
	delete requestQueues[endpointKey];
	return false;
};

const readErrors = () => {
	const errs = responseErrors;
	responseErrors = {};
	if (Object.keys(errs).length > 0) {
		Object.keys(errs).forEach(endpointKey => {
			const err = errs[endpointKey];
			err.onError(err.e);
		});
		return true;
	}
	return false;
};

const wrapOnError = (endpointKey, onError) => e => {
	if (!sendNextRequestInQueue(endpointKey)) {
		// all requests to the endpoint has been processed
		// first let's save the error
		responseErrors[endpointKey] = { onError, e };
		// are there requests to other endpoint not yet finished?
		if (Object.keys(requestQueues).length === 0) {
			readErrors();
		}
	}
};

const wrapOnSuccess = (endpointKey, onSuccess) => () => {
	if (!sendNextRequestInQueue(endpointKey)) {
		// all requests to THE endpoint has been processed
		// are there requests to other endpoint not yet finished?
		if (Object.keys(requestQueues).length === 0) {
			// are there errors?
			if (!readErrors()) {
				onSuccess();
			}
		}
	}
};

const persistAnswer = (pageKey, dataGroupId, sectionKey, fieldKey, answer) => (
	dispatch,
	getState,
) => {
	dispatch(recordAnswer(pageKey, dataGroupId, sectionKey, fieldKey, answer, true));

	// Get full field definition
	const info = findPersistenceInfo(getState(), pageKey, dataGroupId, sectionKey, fieldKey);
	if (info === undefined) {
		return;
	}

	const endpointKey = `${info.endpoint.method}|${info.endpoint.url}|${info.path}`;

	const { pages } = getState().application;
	const { answers } = getState();
	const dataToSave = getDataToSave(pages, answers);

	const onSuccess = () => {
		dispatch(persistAnswerSuccess());
		dispatch(ApplicationActions.loadMetadata());
	};
	const onError = e => dispatch(persistAnswerError(e, info.path));

	const request = () => {
		dispatch(startPersistAnswer());
		BoardingApi.persistAnswer(
			info,
			dataToSave[endpointKey],
			wrapOnSuccess(endpointKey, onSuccess),
			wrapOnError(endpointKey, onError),
		);
	};

	if (info.field.submitOnChange && AnswerHandler.isValid(answer, info.field)) {
		queueRequest(endpointKey, request);
	}
};

const sendData = (endpoint, data) => dispatch => {
	dispatch(startPersistAnswer());
	BoardingApi.saveData(endpoint, data)
		.then(() => {
			dispatch(persistAnswerSuccess());
			dispatch(ApplicationActions.loadMetadata());
		})
		.catch(e => dispatch(persistAnswerError(e)));
};

const reportError = error => dispatch => {
	dispatch(persistAnswerError(error));
};

function readOkResponse(response, path, dispatch) {
	response.text().then(text => {
		try {
			const data = JSON.parse(text);
			dispatch({
				type: READ_OK_RESPONSE,
				payload: {
					path,
					data,
				},
			});
		} catch (e) {
			// not a json, ignore it
		}
	});
}

const sendPagesToAPI = (pages, answers, dispatch, onSuccess) => {
	const dataToSave = getDataToSave(pages, answers);
	Object.keys(dataToSave).forEach(endpointKey => {
		const request = () => {
			const endpoint = {
				method: endpointKey.split('|')[0],
				url: endpointKey.split('|')[1],
			};
			const path = endpointKey.split('|')[2];
			const onOk = response =>
				response && response.ok && readOkResponse(response, path, dispatch);
			const onError = e => dispatch(persistAnswerError(e, path));
			BoardingApi.saveData(endpoint, dataToSave[endpointKey])
				.then(onOk)
				.then(wrapOnSuccess(endpointKey, onSuccess))
				.catch(wrapOnError(endpointKey, onError));
		};
		queueRequest(endpointKey, request);
	});
};

const saveCompleteMerchant = () => (dispatch, getState) => {
	dispatch(startPersistAnswer());
	const { pages } = getState().application;
	const { answers } = getState();
	if (isEntireFormValid(pages, answers)) {
		const onSuccess = () => {
			dispatch(clearPageErrors());
			BoardingApi.commitData(getState().config['merchant.id'])
				.then(() => {
					dispatch(persistAnswerSuccess());
					dispatch(ApplicationActions.loadMetadata());
				})
				.catch(e => dispatch(persistAnswerError(e)));
		};
		sendPagesToAPI(pages, answers, dispatch, onSuccess);
	} else {
		dispatch(persistAnswerError(new Error('Please check all fields')));
	}
};

const savePage = pageId => (dispatch, getState) => {
	const { pages } = getState().application;
	const { answers } = getState();
	const currentPageKey = Object.values(pages).sort(MetadataUtils.positionComparator)[pageId].key;
	if (currentPageKey) {
		const currentPage = pages[currentPageKey];
		if (isEntireFormValid({ [currentPageKey]: currentPage }, answers)) {
			dispatch(startPersistAnswer());
			const onSuccess = () => {
				dispatch(persistAnswerSuccess());
				dispatch(ApplicationActions.loadMetadata());
			};
			sendPagesToAPI({ [currentPageKey]: currentPage }, answers, dispatch, onSuccess);
		}
	}
};

export default {
	recordAnswer,
	persistAnswer,
	saveCompleteMerchant,
	sendData,
	reportError,
	savePage,
	getDataToSave,
};
