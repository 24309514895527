/* eslint-disable class-methods-use-this */
import shortid from 'shortid';
import BaseAnswerHandler from './BaseAnswerHandler';
import StringAnswerHandler from './StringAnswerHandler';

export default class MultiStringAnswerHandler extends BaseAnswerHandler {
	constructor(field) {
		super(field);
		this.textHandler = new StringAnswerHandler(field);
	}

	validate(answer) {
		return answer.map(a => {
			const validatedAnswer = this.textHandler.validate(a);
			return {
				...a,
				...validatedAnswer,
				...this.validateAgainstInitialState(answer),
			};
		});
	}

	extractData(answer) {
		return answer.map(a => a.value);
	}

	createAnswer(value) {
		if (typeof value.map === 'function') {
			return value.map(v => ({
				value: v,
				key: shortid.generate(),
			}));
		}
		return [
			{
				key: shortid.generate(),
				value,
			},
		];
	}

	isValid(answer) {
		const nonValidAnswers = answer.filter(a => !this.textHandler.isValid(a));
		return nonValidAnswers.length === 0;
	}
}
