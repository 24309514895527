import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import T, { TranslationContext } from '@tunz/translation';
import { Confirmation } from '@tunz/ifs-react-lib';

import DataGroup, { dataGroupTemplate } from '../DataGroup';
import style from './Page.module.scss';
import MetadataUtils from '../../utils/MetadataUtils';
import LoadingIndicator from '../LoadingIndicator';

class RepeatableDatagroupPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expandedElement: null,
		};
	}

	toggleCollapsed = index => () => {
		const { expandedElement } = this.state;
		if (expandedElement === index) {
			this.setState({
				expandedElement: null,
			});
		} else {
			this.setState({
				expandedElement: index,
			});
		}
	};

	addElement = event => {
		event.preventDefault();
		const { template, addElement } = this.props;
		addElement(template.endpoint);
	};

	// eslint-disable-next-line no-unused-vars
	removeElement = (dataGroup, getTranslation) => event => {
		event.stopPropagation();
		// eslint-disable-next-line no-unused-vars
		const { removeElement, reportError } = this.props;

		const message = getTranslation('CONFIRMATION_DELETE_ELEMENT') || 'Are you sure ?';
		const title = getTranslation('CONFIRMATION_TITLE') || 'Confirmation';
		const confirmLabel = getTranslation('YES') || 'Yes';
		const cancelLabel = getTranslation('NO') || 'No';

		Confirmation(
			message,
			{
				title,
				confirmLabel,
				cancelLabel,
			},
			100,
			undefined,
		)
			.then(() => {
				removeElement(dataGroup.deleteEndpoint);
			})
			.catch(e => {
				if (e) {
					reportError(e);
				}
			});
	};

	render() {
		const {
			name,
			keyId,
			dataGroups,
			valid,
			error,
			isSaving,
			recordAnswer,
			persistAnswer,
			answers,
			requirementLevel,
		} = this.props;
		const readonly = requirementLevel === 'READONLY';
		const { expandedElement } = this.state;
		const { errorValues } = answers;
		const isAnswerValid = answers.valid === undefined || answers.valid;

		const isValid = valid && isAnswerValid;
		const errorMessage = answers.errorMessage || error;
		const errorCode = answers.errorCode || error;
		return (
			<TranslationContext.Consumer>
				{({ getTranslation }) => (
					<Container fluid>
						<Row>
							<Col lg={12} className={!isValid ? style.error : null}>
								<h2 className={`mt-4 ${style.title}`}>
									<T>{name}</T>
									{!isValid && (
										<small>
											<T
												defaultValue={errorMessage}
												formatValues={errorValues}
											>
												{errorCode}
											</T>
										</small>
									)}
									{!readonly && (
										<small className="float-end">
											<Button
												variant="light"
												onClick={this.addElement}
												className="ADD_BUTTON"
												disabled={isSaving}
											>
												<FaPlus />
												&nbsp;
												<T>ADD_ELEMENT</T>
											</Button>
										</small>
									)}
									{isSaving && (
										<div className="float-end">
											<LoadingIndicator />
										</div>
									)}
								</h2>
							</Col>
						</Row>
						<Row>
							<Col lg={12}>
								{dataGroups.sort(MetadataUtils.positionComparator).map(d => {
									const dgId = d.id;
									const removable = d.deleteEndpoint;
									return (
										<div className={style.collapsibleDG} key={dgId}>
											<DataGroup
												id={dgId}
												{...d}
												answers={answers[dgId]}
												pageKey={keyId}
												collapsible
												collapsed={expandedElement !== dgId}
												toggleCollapsed={this.toggleCollapsed(dgId)}
												remove={
													removable &&
													this.removeElement(d, getTranslation)
												}
												recordAnswer={recordAnswer}
												persistAnswer={persistAnswer}
												readonly={readonly}
											/>
										</div>
									);
								})}
							</Col>
						</Row>
					</Container>
				)}
			</TranslationContext.Consumer>
		);
	}
}

RepeatableDatagroupPage.contextType = TranslationContext;

RepeatableDatagroupPage.propTypes = {
	name: PropTypes.string.isRequired,
	keyId: PropTypes.string.isRequired,
	valid: PropTypes.bool,
	error: PropTypes.string,
	isSaving: PropTypes.bool,
	dataGroups: PropTypes.arrayOf(dataGroupTemplate),
	template: dataGroupTemplate,
	answers: PropTypes.shape({
		valid: PropTypes.bool,
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		errorValues: PropTypes.shape({}),
	}),
	recordAnswer: PropTypes.func,
	persistAnswer: PropTypes.func,
	addElement: PropTypes.func,
	removeElement: PropTypes.func,
	reportError: PropTypes.func,
	requirementLevel: PropTypes.string,
};

RepeatableDatagroupPage.defaultProps = {
	valid: true,
	error: null,
	isSaving: false,
	dataGroups: [],
	template: {
		endpoint: {
			method: '',
			url: '',
		},
		sections: [],
	},
	answers: {
		valid: true,
	},
	recordAnswer: () => undefined,
	persistAnswer: () => undefined,
	addElement: () => undefined,
	removeElement: () => undefined,
	reportError: () => undefined,
	requirementLevel: 'VISIBLE',
};

export default RepeatableDatagroupPage;
