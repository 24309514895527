import { getApplicationData } from '../api/BoardingApi';

export const LOAD_APPLICATION = 'LOAD_APPLICATION';
export const APPLICATION_LOADED = 'APPLICATION_LOADED';
export const LOAD_APPLICATION_FAILED = 'LOAD_APPLICATION_FAILED';
export const FINISH = 'FINISH';

const loadApplicationAction = () => ({
	type: LOAD_APPLICATION,
});

const applicationLoadFailed = error => ({
	type: LOAD_APPLICATION_FAILED,
	payload: error,
});

const applicationLoadedAction = data => ({
	type: APPLICATION_LOADED,
	payload: data,
});

const loadMetadata = () => (dispatch, getState) => {
	dispatch(loadApplicationAction());
	const merchantId = getState().config['merchant.id'];
	return getApplicationData(
		merchantId,
		data => dispatch(applicationLoadedAction(data)),
		error => dispatch(applicationLoadFailed(error)),
	);
};

export default {
	loadMetadata,
};
