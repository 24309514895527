import { Button, FormGroup } from 'react-bootstrap';
import T from '@tunz/translation';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Text from '../fields/text';
import ErrorDisplay from '../ErrorDisplay';
import TunzError from '../../api/TunzError';
import ConfigurationActions from '../../actions/configurationActions';

class TmidVerify extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputValue: '', // Storage for input TMID value
			expectedTmid: props.tmid,
			tmidValid: null, // Storage for TMID validation
		};
		this.setTmid = this.setTmid.bind(this);
		this.verifyTmid = this.verifyTmid.bind(this);
	}

	setTmid(keyId, newValue) {
		this.setState({ inputValue: newValue.value.trim(), tmidValid: null }); // Set the input TMID value in the state
	}

	verifyTmid() {
		const { expectedTmid } = this.state;

		if (expectedTmid === this.state.inputValue) {
			this.setState({ tmidValid: true });
			this.props.setTmidVerified();
		} else {
			this.setState({ tmidValid: false });
		}
	}

	render() {
		const { keyId, name } = this.props;
		const { tmidValid } = this.state;
		const errorMessage =
			tmidValid === false
				? 'TMID is invalid, insert a TMID corresponding to the boarding link'
				: null;
		return (
			<FormGroup>
				{tmidValid === false ? (
					<ErrorDisplay error={new TunzError('AbortError', errorMessage)} />
				) : null}
				<Text
					name={name}
					keyId={keyId}
					min={0}
					max={15}
					readonly={false}
					onChange={this.setTmid}
					answer={{ value: this.state.inputValue }}
				/>
				<Button onClick={this.verifyTmid}>
					<T>VERIFY_TMID_BUTTON</T>
				</Button>
			</FormGroup>
		);
	}
}

TmidVerify.propTypes = {
	keyId: PropTypes.string.isRequired,
	tmid: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	setTmidVerified: PropTypes.func.isRequired,
	answer: PropTypes.shape({
		value: PropTypes.string.isRequired,
	}),
};

TmidVerify.defaultProps = {
	answer: { value: '' },
};

const mapStateToProps = state => ({
	tmidValid: state.isTmidVerified,
});

const mapDispatchToProps = dispatch => ({
	setTmidVerified: () => dispatch(ConfigurationActions.setTmidVerified()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TmidVerify);
