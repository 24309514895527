import React from 'react';
import PropTypes from 'prop-types';
import T from '@tunz/translation';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';

import style from './SessionExpired.module.scss';

const SessionExpired = ({ onSessionExpiredReload }) => (
	<Container>
		<Row className="justify-content-md-center">
			<Col lg="6">
				<Alert variant="danger" className={style.sessionExpiredAlert}>
					<h2>
						<T>SESSION_TIMED_OUT_TITLE</T>
					</h2>
					<div className={style.text}>
						<T>SESSION_TIMED_OUT_MESSAGE</T>
					</div>
					<div className={style.buttons}>
						<Button variant="danger" onClick={onSessionExpiredReload}>
							<T>START_OVER</T>
						</Button>
					</div>
				</Alert>
			</Col>
		</Row>
	</Container>
);

SessionExpired.propTypes = {
	onSessionExpiredReload: PropTypes.func,
};

SessionExpired.defaultProps = {
	onSessionExpiredReload: () => undefined,
};

export default SessionExpired;
