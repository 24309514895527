import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { FaSyncAlt } from 'react-icons/fa';
import T from '@tunz/translation';

import style from './BoardingLinkRequestor.module.scss';
import Email from '../../components/fields/email/Email';
import EmailAnswerHandler from '../../AnswerHandler/EmailAnswerHandler';
import Loading from '../../components/Loading';

const emailAnswerHandler = new EmailAnswerHandler({ requirementLevel: 'REQUIRED' });

function BoardingLinkRequestor({
	isOpen,
	open,
	close,
	requestLink,
	isRequestSuccessful,
	isRequestFailed,
	isLoading,
	requestFailDetails,
}) {
	const [answer, updateAnswer] = useState({ value: '' });

	const handleSendRequest = () => {
		const validatedAnswer = emailAnswerHandler.validate(answer);
		updateAnswer(validatedAnswer);
		if (validatedAnswer.valid) {
			requestLink(validatedAnswer.value);
		}
	};

	return isOpen ? (
		<div className={style.linkRequestor}>
			<Form>
				<Row className="align-items-center">
					<Col xs="auto">
						<Email
							name="BOARDING_LINK_ENTER_YOUR_EMAIL"
							keyId="BOARDING_LINK_ENTER_YOUR_EMAIL"
							onChange={(_, a) => updateAnswer(a)}
							answer={answer}
							hidesLabel
							placeholder="Email"
						/>
					</Col>
					<Col xs="auto">
						<Button onClick={handleSendRequest}>
							<T>SEND_BOARDING_LINK_REQUEST</T>
						</Button>
					</Col>
				</Row>
			</Form>
			{isLoading && <Loading silent />}
			{isRequestSuccessful && (
				<Alert variant="success">
					<FaSyncAlt glyph="ok" /> <T>BOARDING_LINK_SEND_SUCCESS</T>
				</Alert>
			)}
			{isRequestFailed && (
				<Alert variant="danger">
					<FaSyncAlt /> <T>{requestFailDetails}</T>
				</Alert>
			)}
			<Button variant="link" onClick={close}>
				<T>BACK</T>
			</Button>
		</div>
	) : (
		<div className={style.linkRequestor}>
			<Button variant="link" onClick={open}>
				<T>GET_BOARDING_LINK</T>
			</Button>
		</div>
	);
}

BoardingLinkRequestor.propTypes = {
	isOpen: PropTypes.bool,
	isRequestSuccessful: PropTypes.bool,
	isRequestFailed: PropTypes.bool,
	isLoading: PropTypes.bool,
	requestFailDetails: PropTypes.string,
	open: PropTypes.func,
	close: PropTypes.func,
	requestLink: PropTypes.func,
};

BoardingLinkRequestor.defaultProps = {
	isOpen: false,
	isRequestSuccessful: false,
	isRequestFailed: false,
	isLoading: false,
	requestFailDetails: null,
	open: () => undefined,
	close: () => undefined,
	requestLink: () => undefined,
};

export default BoardingLinkRequestor;
