import HttpClient, { setAllowedApplicationId } from '@tunz/http';

import TunzError from './TunzError';

setAllowedApplicationId('BOARDING');

const apiUrl = 'api/boardingAPI';
const metadataUrl = `${apiUrl}/metadata/companies/`;
const merchantUrl = `${apiUrl}/merchants`;
const commitUrl = `${apiUrl}/commit/companies`;
const getBoardingLinkUrl = `${apiUrl}/boardingUrls`;

let abortController;
let echoCounter = 0;

const checkEchoHeader = response => {
	if (response.headers) {
		const responseEcho = parseInt(response.headers.echo, 10);
		if (Number.isInteger(responseEcho)) {
			if (responseEcho && responseEcho !== echoCounter) {
				throw new TunzError('AbortError', 'Obsolete request');
			}
		}
	}
	return undefined;
};

const httpCall = async (
	url,
	method = 'GET',
	values = {},
	checkEcho = false,
	signal = undefined,
) => {
	echoCounter += 1;
	const params = {
		url,
		credentials: 'include',
		method,
		signal,
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			echo: echoCounter,
		},
	};

	if (method === 'POST' || method === 'PUT') {
		params.data = JSON.stringify(values);
	}

	try {
		const response = await HttpClient(params);
		if (checkEcho) {
			checkEchoHeader(response);
		}
		return response.data;
	} catch (e) {
		const { response, message } = e;

		if (message === 'canceled') {
			return Promise.reject(new TunzError('AbortError', 'Canceled request'));
		}

		if (response) {
			if (response.status === 401) {
				return Promise.reject(
					new TunzError(
						'HTTP_ERROR_401',
						'You credentials have expired, please refresh the page',
					),
				);
			}

			if (response.status === 403) {
				return Promise.reject(
					new TunzError(
						'HTTP_ERROR_403',
						'You do not have the right to perform this action',
					),
				);
			}

			return Promise.reject(response.data);
		}
		return Promise.reject(e);
	}
};

export const getApplicationData = async (merchantId, onSuccess, onError) => {
	if (abortController !== undefined) {
		abortController.abort();
	}
	abortController = new AbortController();
	try {
		const applicationData = await httpCall(
			metadataUrl + merchantId,
			'GET',
			{},
			true,
			abortController.signal,
		);
		onSuccess(applicationData);
	} catch (err) {
		if (err.name !== 'AbortError' && err.code !== 'AbortError') {
			onError(err);
		}
	}
};

export const createMerchant = async (onSuccess, onError) => {
	try {
		onSuccess(await httpCall(merchantUrl, 'POST'));
	} catch (e) {
		onError(e);
	}
};

export const persistAnswer = async (info, values, onSuccess, onError) => {
	try {
		onSuccess(await httpCall(info.endpoint.url, info.endpoint.method, values));
	} catch (e) {
		onError(e);
	}
};

export const saveData = (endpoint, values) => httpCall(endpoint.url, endpoint.method, values);

export const commitData = companyId => httpCall(`${commitUrl}/${companyId}`, 'POST');

export const sendBoardingLinkRequest = email =>
	httpCall(`${getBoardingLinkUrl}?email=${email}`, 'GET');
