import React from 'react';
import PropTypes from 'prop-types';
import style from './BadgeIndicator.module.scss';

const BadgeIndicator = ({ value }) => <div className={style.errorIndicator}>{value}</div>;

BadgeIndicator.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

BadgeIndicator.defaultProps = {
	value: '!',
};

export default BadgeIndicator;
