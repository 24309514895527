/* eslint-disable class-methods-use-this */
import BaseAnswerHandler from './BaseAnswerHandler';
import Errors from './ValidationErrors';

export default class BooleanAnswerHandler extends BaseAnswerHandler {
	validate(answer, initialAnswer) {
		if (initialAnswer) {
			this.initialState = initialAnswer;
		}
		const { value } = answer;
		let valid = true;
		let error = null;
		const mandatory = this.field.requirementLevel === 'REQUIRED';

		if (mandatory && typeof value !== 'boolean') {
			valid = false;
			error = Errors.MANDATORY_FIELD;
		}
		return {
			value,
			valid,
			...error,
			...this.validateAgainstInitialState(answer),
		};
	}

	createAnswer(value) {
		if (typeof value === 'boolean') {
			return { value };
		}
		if (typeof value === 'string') {
			return { value: value.toLocaleLowerCase() === 'true' };
		}
		return { value: false };
	}
}
