import { PhoneNumberFormat as PNF, PhoneNumberUtil } from 'google-libphonenumber';

import BaseAnswerHandler from './BaseAnswerHandler';
import Errors from './ValidationErrors';

class PhoneNumberAnswerHandler extends BaseAnswerHandler {
	constructor(field) {
		super(field);
		this.phoneUtil = PhoneNumberUtil.getInstance();
	}

	validate(answer, initialAnswer) {
		if (initialAnswer) {
			this.initialState = initialAnswer;
		}
		let { value, country } = answer;
		let valid = true;
		let error = null;
		let errorValues = null;
		const mandatory = this.field.requirementLevel === 'REQUIRED';
		const valuePresent = value !== undefined && value.length > 0;

		try {
			if (valuePresent) {
				// Here the country is only the default location to start parsing the number from.
				// if the user enters a number that matches another country, it will be recognised.
				const number = this.phoneUtil.parse(value, 'BE');

				if (this.phoneUtil.isValidNumber(number)) {
					value = this.phoneUtil.format(number, PNF.INTERNATIONAL);
					country = this.phoneUtil.getRegionCodeForNumber(number);
				} else {
					valid = false;
					error = Errors.PHONE_NUMBER_NOT_RECOGNIZED;
					errorValues = { value };
				}
			}
		} catch (e) {
			valid = false;
			error = Errors.PHONE_NUMBER_NOT_RECOGNIZED;
			errorValues = { value };
		}

		const re = new RegExp(this.field.constraint.pattern);
		if (mandatory && !valuePresent) {
			valid = false;
			error = Errors.MANDATORY_FIELD;
		} else if (valuePresent && !re.test(value)) {
			valid = false;
			error = Errors.PHONE_NUMBER_NOT_RECOGNIZED;
			errorValues = { value };
		}

		return {
			value,
			country,
			valid,
			...error,
			errorValues,
			...this.validateAgainstInitialState(answer),
		};
	}

	extractData(answer) {
		const { value } = answer;
		try {
			const number = this.phoneUtil.parse(value); // already in international format
			return this.phoneUtil.format(number, PNF.E164);
		} catch (e) {
			return value;
		}
	}
}

export default PhoneNumberAnswerHandler;
