import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslationContext } from '@tunz/translation';
import mime from 'mime';

import { ConstraintUtils } from '../../utils';
import Text from './text';
import MultiText from './multiText';
import MultiObject from './multiObject';
import Password from './password';
import NumberInput from './number';
import Email from './email';
import MultiValue from './multiValue';
import FreeText from './freeText';
import Radio from './radio';
import Checkboxes from './checkbox';
import DatePickerInput from './datePicker';
import BooleanInput from './boolean';
import MultiFileUpload from './MultiFileUpload';
import PhoneNumber from './phoneNumber';
import Iban from './iban';
import Bic from './bic';
import MultiSelection from './multiSelection';
import Label from './label';
import RegexBounded from './regexBounded';
import Masked from './masked';
import ComboBox from './combobox';
import DownloadButton from './DownloadButton';

const DEFAULT_TITLE_SIZE = 2;
const GRID_SIZE = 4;

class Fields extends Component {
	shouldComponentUpdate(nextProps) {
		return !_.isEqual(nextProps, this.props);
	}

	getSpecificField({ placeholder, tooltip, currentLanguage }) {
		const {
			name,
			keyId,
			constraint,
			requirementLevel,
			direction,
			step,
			valid,
			answer,
			recordAnswer,
			persistAnswer,
		} = this.props;
		if (requirementLevel === 'HIDDEN') {
			return null;
		}

		const titleSize = constraint.titleSize || DEFAULT_TITLE_SIZE;
		const fieldSize = GRID_SIZE - titleSize;

		const commonProps = {
			name,
			keyId,
			onChange: recordAnswer,
			onEditEnd: persistAnswer,
			answer,
			mandatory: requirementLevel === 'REQUIRED',
			readonly: requirementLevel === 'READONLY',
			valid,
			titleSize,
			fieldSize,
			placeholder,
			tooltip,
			currentLanguage,
		};

		switch (constraint.type.toLowerCase()) {
			case 'string':
				const stringLength = ConstraintUtils.extractIntegerRange(constraint.length);
				return <Text {...commonProps} min={stringLength.min} max={stringLength.max} />;
			case 'bic':
				return (
					<Bic {...commonProps} recordAnswer={recordAnswer} values={constraint.values} />
				);
			case 'iban':
				return (
					<Iban {...commonProps} recordAnswer={recordAnswer} values={constraint.values} />
				);
			case 'telephone_number':
				return <PhoneNumber {...commonProps} defaultCountry={constraint.defaultCountry} />;
			case 'multi_string':
				const partLength = ConstraintUtils.extractIntegerRange(constraint.partLength);
				return (
					<MultiText
						{...commonProps}
						recordAnswer={recordAnswer}
						min={partLength.min}
						max={partLength.max}
					/>
				);
			case 'multi_object':
				const length = ConstraintUtils.extractRangeFromMultiObject(constraint);
				return (
					<MultiObject
						{...commonProps}
						recordAnswer={recordAnswer}
						min={length.min}
						max={length.max}
					/>
				);

			case 'password':
				return <Password {...commonProps} />;

			case 'number':
			case 'integer':
				const numberRange = ConstraintUtils.extractIntegerRange(constraint.range);
				return (
					<NumberInput
						{...commonProps}
						min={numberRange.min}
						max={numberRange.max}
						step={step}
					/>
				);

			case 'email':
				return <Email {...commonProps} />;

			case 'enum':
			case 'enum_label':
				const enumValues = ConstraintUtils.arrayToObject(constraint.values);
				return (
					<MultiValue
						{...commonProps}
						values={enumValues}
						translationPrefix={constraint.translationPrefix}
					/>
				);

			case 'keyvalue':
				return (
					<MultiValue
						{...commonProps}
						values={constraint.allowedValues}
						translationPrefix={constraint.translationPrefix}
					/>
				);

			case 'freetext':
			case 'text':
				return <FreeText {...commonProps} />;

			case 'radio':
				return <Radio {...commonProps} values={constraint.values} direction={direction} />;

			case 'multienum':
				const answerValue =
					answer && answer.value && answer.value.constructor !== Array
						? {
								...answer,
								value: [answer.value],
						  }
						: answer;
				return (
					<Checkboxes
						id={keyId}
						{...commonProps}
						values={constraint.values}
						direction={direction}
						answer={answerValue}
					/>
				);

			case 'date':
				const { min, max } = ConstraintUtils.extractRange(constraint.range);
				return <DatePickerInput id={keyId} {...commonProps} minDate={min} maxDate={max} />;

			case 'boolean':
				return (
					<BooleanInput
						{...commonProps}
						answer={{
							...answer,
							value: ConstraintUtils.getBool(answer),
						}}
					/>
				);

			case 'multi_doc_file':
				return (
					<MultiFileUpload
						{...commonProps}
						acceptedFileTypes={constraint.acceptedFileTypes?.map(mime.getType)}
						maxNumberOfFiles={constraint.maxNumberOfFiles}
						maxFileSize={constraint.maxFileSize}
					/>
				);

			case 'multi_selection':
				return <MultiSelection {...commonProps} />;

			case 'label':
				return <Label {...commonProps} />;

			case 'regex':
				return <RegexBounded {...commonProps} />;

			case 'masked':
				return (
					<Masked
						{...commonProps}
						maskChar={constraint.maskChar}
						mask={constraint.mask}
						alwaysShowMask={constraint.alwaysShowMask}
						formatChars={constraint.formatChars && JSON.parse(constraint.formatChars)}
					/>
				);
			case 'combo_box':
				return (
					<ComboBox
						{...commonProps}
						allowedValues={constraint.allowedValues}
						onChange={recordAnswer}
						onEditEnd={persistAnswer}
					/>
				);
			case 'document_download':
				return <DownloadButton {...commonProps.answer.value} />;
			default:
				return <Text {...commonProps} />;
		}
	}

	render() {
		const { name, sectionKey } = this.props;
		return (
			<TranslationContext.Consumer>
				{({ getTranslation, currentLanguage }) => {
					const placeholder = getTranslation(`PLACEHOLDER_${sectionKey}_${name}`, false);
					return this.getSpecificField({
						placeholder,
						tooltip: `TOOLTIP_${sectionKey}_${name}`,
						currentLanguage,
					});
				}}
			</TranslationContext.Consumer>
		);
	}
}

Fields.propTypes = {
	constraint: PropTypes.shape({
		type: PropTypes.string.isRequired,
		length: PropTypes.string,
		values: PropTypes.arrayOf(PropTypes.string),
		titleSize: PropTypes.number,
		range: PropTypes.string,
		allowedValues: PropTypes.objectOf(PropTypes.string),
		partLength: PropTypes.string,
		maxNumberOfFiles: PropTypes.number,
		maxFileSize: PropTypes.number,
		translationPrefix: PropTypes.string,
		defaultCountry: PropTypes.string,
		acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
		maskChar: PropTypes.string,
		mask: PropTypes.string,
		alwaysShowMask: PropTypes.bool,
		// eslint-disable-next-line react/forbid-prop-types
		formatChars: PropTypes.object,
	}).isRequired,
	requirementLevel: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	keyId: PropTypes.string.isRequired,
	answer: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
		PropTypes.array,
		PropTypes.bool,
	]),

	direction: PropTypes.string,
	recordAnswer: PropTypes.func.isRequired,
	persistAnswer: PropTypes.func.isRequired,
	step: PropTypes.number,
	valid: PropTypes.bool,
	pageKey: PropTypes.string,
	sectionKey: PropTypes.string,
};

Fields.defaultProps = {
	direction: 'HORIZONTAL',
	answer: undefined,
	step: 1,
	valid: true,
	pageKey: '',
	sectionKey: '',
};

export default Fields;
