import { PureComponent } from 'react';
import PropTypes from 'prop-types';

class SimpleField extends PureComponent {
	recordAnswer = event => {
		const { value } = event.target;
		const { keyId, onChange } = this.props;
		onChange(keyId, { value });
	};

	endEditing = () => {
		const { keyId, onEditEnd, answer } = this.props;
		onEditEnd(keyId, answer);
	};

	isValid() {
		const { answer } = this.props;
		if (answer && answer.valid !== undefined) {
			return answer.valid;
		}
		return null;
	}
}

SimpleField.propTypes = {
	keyId: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	onEditEnd: PropTypes.func,
	answer: PropTypes.shape({
		value: PropTypes.string.isRequired,
		valid: PropTypes.bool,
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		// eslint-disable-next-line react/forbid-prop-types
		errorValues: PropTypes.any,
	}),
};

SimpleField.defaultProps = {
	answer: { value: '' },
	onChange: () => undefined,
	onEditEnd: () => undefined,
};

export default SimpleField;
