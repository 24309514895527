import * as ACTIONS from '../../actions/userActions';

const initialState = {
	open: false,
	loading: false,
	succeeded: false,
	error: false,
	errorMessage: null,
};

const getBoardingLinkReducer = (state = initialState, action = { type: '' }) => {
	switch (action.type) {
		case ACTIONS.OPEN_BOARDING_LINK:
			return {
				...state,
				open: true,
				succeeded: false,
				error: false,
				errorMessage: null,
			};

		case ACTIONS.CLOSE_BOARDING_LINK:
			return {
				...state,
				open: false,
			};

		case ACTIONS.SEND_BOARDING_LINK_REQUEST:
			return {
				...state,
				loading: true,
				succeeded: false,
				error: false,
				errorMessage: null,
			};

		case ACTIONS.SEND_BOARDING_LINK_SUCCESS:
			return {
				...state,
				loading: false,
				succeeded: true,
				error: false,
				errorMessage: null,
			};

		case ACTIONS.SEND_BOARDING_LINK_FAILED:
			return {
				...state,
				loading: false,
				succeeded: false,
				error: true,
				errorMessage: action.payload,
			};

		default:
			return state;
	}
};

export default getBoardingLinkReducer;
