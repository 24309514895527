import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import T from '@tunz/translation';
import InfoTip from '../../InfoTip';
import FieldErrorFeedback from '../FieldErrorFeedback';
import SimpleField from '../SimpleField';

class Text extends SimpleField {
	render() {
		const {
			name,
			keyId,
			answer,
			min,
			max,
			mandatory,
			readonly,
			placeholder,
			tooltip,
		} = this.props;
		const { value, errorMessage, errorCode, errorValues } = answer;
		const valid = this.isValid();
		const invalid = valid === null ? null : !valid;

		return (
			<FormGroup className="mb-3">
				<FormLabel>
					<T>{name}</T>
					{mandatory && <span>*</span>}
					<InfoTip text={tooltip} />
				</FormLabel>
				<FormControl
					id={keyId}
					type="text"
					placeholder={placeholder}
					onChange={this.recordAnswer}
					onBlur={this.endEditing}
					value={value}
					minLength={min}
					maxLength={max}
					name={name}
					disabled={readonly}
					isValid={valid}
					isInvalid={invalid}
				/>
				<FieldErrorFeedback
					errorCode={errorCode}
					defaultValue={errorMessage}
					formatValues={errorValues}
				/>
			</FormGroup>
		);
	}
}

Text.propTypes = {
	keyId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	min: PropTypes.number,
	max: PropTypes.number,
	onChange: PropTypes.func,
	onEditEnd: PropTypes.func,
	answer: PropTypes.shape({
		value: PropTypes.string.isRequired,
		valid: PropTypes.bool,
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		// eslint-disable-next-line react/forbid-prop-types
		errorValues: PropTypes.any,
	}),
	mandatory: PropTypes.bool,
	readonly: PropTypes.bool,
	placeholder: PropTypes.string,
	tooltip: PropTypes.string,
};

Text.defaultProps = {
	min: 0,
	max: 10000,
	mandatory: false,
	readonly: false,
	onChange: () => undefined,
	onEditEnd: () => undefined,
	answer: { value: '' },
	placeholder: '',
	tooltip: null,
};

export default Text;
