import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import T, { TranslationContext } from '@tunz/translation';
import { Col, Container, Row } from 'react-bootstrap';
import style from '../RequirementForm.module.scss';

import MerchatUtils from '../../../utils/MerchantUtils';

const NoRequiredActionNotification = ({ name, config }) => {
	const { currentLanguage } = useContext(TranslationContext);

	const country = currentLanguage.split('-')[1].toLowerCase();

	const translationValues = {
		merchantId: MerchatUtils.buildTmid(config['merchant.id']),
		phone: config[`help_phone_${country}`] || config.help_phone,
		mail: config[`help_email_${country}`] || config.help_email,
		shortName: config['ingenico.name.short'],
	};

	return (
		<>
			<h1 className={`${style.requirementTitle} requirement-title`}>
				<T>{name}</T>
			</h1>
			<Container fluid>
				<Row>
					<Col lg={12}>
						<div className={style.noActionNotification}>
							<h1>
								<T>{`${name}_TITLE`}</T>
							</h1>
							<p>
								<T formatValues={translationValues}>{`${name}_TEXT`}</T>
							</p>
						</div>
					</Col>
				</Row>
			</Container>
			{translationValues.merchantId && (
				<input type="hidden" id="merchantId" value={translationValues.merchantId} />
			)}
		</>
	);
};

NoRequiredActionNotification.propTypes = {
	name: PropTypes.string.isRequired,
	config: PropTypes.shape(),
};

NoRequiredActionNotification.defaultProps = {
	config: {},
};

export default NoRequiredActionNotification;
