import { ConstraintUtils } from '../utils/index';
import BaseAnswerHandler from './BaseAnswerHandler';
import Errors from './ValidationErrors';

export default class StringAnswerHandler extends BaseAnswerHandler {
	validate(answer, initialAnswer) {
		if (initialAnswer) {
			this.initialState = initialAnswer;
		}
		const { value } = answer;
		let valid = true;
		let error = null;
		let errorValues = null;
		const mandatory = this.field.requirementLevel === 'REQUIRED';
		const { min, max } = ConstraintUtils.extractIntegerRange(this.field.constraint.length);

		if (mandatory && value.length === 0) {
			valid = false;
			error = Errors.MANDATORY_FIELD;
		} else if (max !== null && value.length > max) {
			valid = false;
			error = Errors.MAX_LENGTH_EXCEEDED;
			errorValues = {
				value,
				max,
			};
		} else if (value.length < min && (mandatory || value.length > 0)) {
			valid = false;
			error = Errors.MIN_LENGTH_NOT_REACHED;
			errorValues = {
				value,
				min,
			};
		}
		return {
			value,
			valid,
			...error,
			errorValues,
			...this.validateAgainstInitialState(answer),
		};
	}
}
