import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { FaArrowCircleDown, FaRegTrashAlt, FaCloudDownloadAlt } from 'react-icons/fa';
import filesize from 'filesize';

import style from './FileItem.module.scss';

const FileItem = ({ file, removeFile, retrieveFile }) => {
	const fileSize = file.data
		? new File([file.data], file.filename, { type: file.type }).size
		: undefined;
	const Element = file.id ? FaCloudDownloadAlt : FaArrowCircleDown;
	return (
		<Alert variant={file.id ? 'success' : 'secondary'} className={style.fileItem}>
			<div className={style.firstLine}>
				<p>{file.filename}</p>
				{removeFile && (
					<FaRegTrashAlt
						className={`${style.removeBtn} remove-button`}
						onClick={() => removeFile(file.filename)}
					/>
				)}
				{retrieveFile && (
					<Element
						className={`${style.removeBtn} download-button`}
						onClick={() => retrieveFile(file.filename)}
					/>
				)}
			</div>
			{fileSize !== undefined && (
				<div className={style.secondLine}>
					<p>{filesize(fileSize)}</p>
				</div>
			)}
			{file.id && (
				<div className={style.secondLine}>
					<p>Uploaded to the server</p>
				</div>
			)}
		</Alert>
	);
};

FileItem.propTypes = {
	file: PropTypes.shape({
		filename: PropTypes.string.isRequired,
		id: PropTypes.number,
		data: PropTypes.string,
		type: PropTypes.string,
	}).isRequired,
	removeFile: PropTypes.func,
	retrieveFile: PropTypes.func,
};

FileItem.defaultProps = {
	removeFile: undefined,
	retrieveFile: undefined,
};

export default FileItem;
