import BaseAnswerHandler from './BaseAnswerHandler';

export default class MultiSelectionAnswerHandler extends BaseAnswerHandler {
	// eslint-disable-next-line class-methods-use-this
	createAnswer(value, previousValue) {
		if (previousValue === undefined || !Array.isArray(previousValue)) {
			return { value };
		}

		// If we have a previous value, set its selected state to avoid problem linked to
		// value getting deselected by themselves due to submitOnChange (TUNZ-15169)

		// Do not push previous values that are not in the metadata because
		// it would hide the fact that an answer has been removed in the metadata (TUNZ-21795)
		const finalValue = [...value];
		previousValue.forEach(v => {
			const existingValue = finalValue.find(ev => ev.group === v.group && ev.name === v.name);
			if (existingValue !== undefined) {
				existingValue.selected = v.selected;
			}
		});
		return { value: finalValue };
	}

	validate(answer, initialAnswer) {
		if (initialAnswer) {
			this.initialState = initialAnswer;
		}
		const { value } = answer;
		// Do not check for mandatory answer because it ruins the user experience (displays error right away
		// when getting on the page) and prevents submitOnChange when everything is unchecked

		return {
			value,
			valid: true,
			...this.validateAgainstInitialState(answer),
		};
	}
}
