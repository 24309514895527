const base64ToArrayBuffer = _base64Str => {
	const binaryString = window.atob(_base64Str);
	const binaryLen = binaryString.length;
	const bytes = new Uint8Array(binaryLen);
	for (let i = 0; i < binaryLen; i += 1) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	return bytes;
};

// eslint-disable-next-line import/prefer-default-export
export const displayFile = (base64str, _contentType) => {
	const byte = base64ToArrayBuffer(base64str);
	const blob = new Blob([byte], { type: _contentType });
	window.open(URL.createObjectURL(blob), '_blank');
};
