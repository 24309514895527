import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormCheck, Col, FormGroup, Container, FormControl, Row } from 'react-bootstrap';
import T from '@tunz/translation';
import InfoTip from '../../InfoTip';
import style from './MultiSelection.module.scss';

class MultiSelection extends Component {
	static arrangeValuesByGroup(answer) {
		const valuesInGroups = {};
		if (answer !== undefined && answer.value !== undefined) {
			answer.value.forEach(v => {
				let groupLabel = v.group;
				if (groupLabel === undefined || typeof groupLabel !== 'string') {
					groupLabel = '';
				}
				const group = valuesInGroups[groupLabel] || [];
				group.push(v);
				valuesInGroups[groupLabel] = group;
			});
		}
		return valuesInGroups;
	}

	recordChange = (group, value) => () => {
		const { keyId, answer, onEditEnd } = this.props;
		const modifiedValue = answer.value.map(a => {
			if (a.name === value && (a.group === undefined || a.group === group)) {
				return {
					...a,
					selected: !a.selected,
				};
			}
			return a;
		});

		onEditEnd(keyId, { value: modifiedValue });
	};

	setSelectionInGroup = (groupName, shouldBeSelected) => () => {
		const { keyId, answer, onEditEnd, readonly } = this.props;

		const canBeChanged = item =>
			item.group === groupName && item.readonly !== true && !readonly;

		onEditEnd(keyId, {
			value: answer.value.map(v => ({
				...v,
				selected: canBeChanged(v) ? shouldBeSelected : v.selected,
			})),
		});
	};

	render = () => {
		const { keyId, answer, readonly, tooltip } = this.props;

		const groups = MultiSelection.arrangeValuesByGroup(answer);
		const { errorMessage, errorCode, errorValues } = answer || {};
		return (
			<div>
				<InfoTip text={tooltip} />
				{errorCode && (
					<FormGroup>
						<FormControl.Feedback type="invalid">
							<T defaultValue={errorMessage} formatValues={errorValues}>
								{errorCode}
							</T>
						</FormControl.Feedback>
					</FormGroup>
				)}
				<FormGroup className="mb-3" controlId={keyId}>
					<Container fluid>
						{Object.keys(groups).map(g => (
							<Fragment key={g}>
								<Row key={`${g}_title`}>
									{g !== '' && (
										<Col lg={12}>
											<h4 className={style.title}>
												<T>{g}</T>
												<span className={style.selectAll}>
													&nbsp;
													<span
														className="select-all"
														role="button"
														tabIndex={0}
														onClick={this.setSelectionInGroup(g, true)}
													>
														<T>SELECT_ALL</T>
													</span>
													&nbsp;
													<span
														className="unselect-all"
														role="button"
														tabIndex={0}
														onClick={this.setSelectionInGroup(g, false)}
													>
														<T>UNSELECT_ALL</T>
													</span>
												</span>
											</h4>
										</Col>
									)}
								</Row>
								<Row key={`${g}_body`}>
									{groups[g].map(v => (
										<Col lg={3} key={`${g}__${v.name}`}>
											<FormCheck
												type="checkbox"
												className={`my-3 ${v.name}`}
												checked={v.selected}
												name={`${g}__${v.name}`}
												id={`${g}__${v.name}`}
												disabled={v.readonly || readonly}
												onChange={this.recordChange(g, v.name)}
												label={
													<div className={style.value}>
														<T defaultValue={v.name}>
															{`IFS_BOARDING_${v.name}`}
														</T>
													</div>
												}
											/>
										</Col>
									))}
								</Row>
							</Fragment>
						))}
					</Container>
				</FormGroup>
			</div>
		);
	};
}

MultiSelection.propTypes = {
	keyId: PropTypes.string.isRequired,
	answer: PropTypes.shape({
		value: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string.isRequired,
				selected: PropTypes.bool.isRequired,
				readonly: PropTypes.bool,
				group: PropTypes.string,
			}),
		).isRequired,
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		// eslint-disable-next-line react/forbid-prop-types
		errorValues: PropTypes.any,
	}).isRequired,
	onEditEnd: PropTypes.func,
	readonly: PropTypes.bool,
	tooltip: PropTypes.string,
};

MultiSelection.defaultProps = {
	onEditEnd: () => undefined,
	readonly: false,
	tooltip: null,
};
export default MultiSelection;
