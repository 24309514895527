import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslationContext } from '@tunz/translation';
import { DownloadElement } from '@tunz/ifs-react-lib';
import { connect } from 'react-redux';

const DownloadButton = ({ title, description, link, loading }) => {
	const { getTranslation } = useContext(TranslationContext);
	return (
		<div className="container-fluid">
			<h5>{getTranslation(title)}</h5>
			<p>{getTranslation(description)}</p>
			<DownloadElement
				url={link}
				variant="primary"
				size="regular"
				text={getTranslation('REQ_DOWNLOAD_BUTTON')}
				inactive={loading}
			/>
		</div>
	);
};

DownloadButton.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	link: PropTypes.string,
	loading: PropTypes.bool,
};

DownloadButton.defaultProps = {
	title: '',
	description: '',
	link: undefined,
	loading: false,
};

const mapStateToProps = state => ({
	loading: state.application.isSaving || state.application.isLoading,
});

export default connect(mapStateToProps)(DownloadButton);
