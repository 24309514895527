import React from 'react';
import PropTypes from 'prop-types';
import T from '@tunz/translation';

const Disclaimer = ({ translationCode, values }) => (
	<li>
		<T formatValues={values}>{translationCode}</T>
	</li>
);

Disclaimer.propTypes = {
	translationCode: PropTypes.string.isRequired,
	values: PropTypes.objectOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.arrayOf(PropTypes.string),
		]),
	),
};

Disclaimer.defaultProps = {
	values: {},
};

// eslint-disable-next-line react/forbid-foreign-prop-types
export const disclaimerType = PropTypes.shape(Disclaimer.propTypes);

export default Disclaimer;
