import React from 'react';
import T from '@tunz/translation';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

function FieldErrorFeedback({ errorCode, formatValues, defaultValue, className }) {
	if (errorCode) {
		return (
			<Form.Control.Feedback type="invalid" className={className}>
				<T defaultValue={defaultValue} formatValues={formatValues}>
					{errorCode}
				</T>
			</Form.Control.Feedback>
		);
	}
	return null;
}

FieldErrorFeedback.propTypes = {
	errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	defaultValue: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	formatValues: PropTypes.any,
	className: PropTypes.string,
};

FieldErrorFeedback.defaultProps = {
	errorCode: undefined,
	defaultValue: undefined,
	formatValues: {},
	className: '',
};

export default FieldErrorFeedback;
