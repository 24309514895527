import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import T from '@tunz/translation';
import InputMask from 'react-input-mask';

import InfoTip from '../../InfoTip';
import FieldErrorFeedback from '../FieldErrorFeedback';
import SimpleField from '../SimpleField';

class Masked extends SimpleField {
	render() {
		const {
			name,
			keyId,
			answer,
			mandatory,
			readonly,
			tooltip,
			maskChar,
			mask,
			alwaysShowMask,
			formatChars,
		} = this.props;
		const { value, errorMessage, errorCode, errorValues } = answer;

		const valid = this.isValid();
		const invalid = valid === null ? null : !valid;
		return (
			<FormGroup>
				<FormLabel>
					<T>{name}</T>
					{mandatory && <span>*</span>}
					<InfoTip text={tooltip} />
				</FormLabel>
				<FormControl
					as={InputMask}
					id={keyId}
					onChange={this.recordAnswer}
					mask={mask}
					onBlur={this.endEditing}
					onKeyUp={this.checkValidity}
					value={value}
					maskChar={maskChar}
					disabled={readonly}
					alwaysShowMask={alwaysShowMask}
					isValid={valid}
					formatChars={formatChars}
					isInvalid={invalid}
				/>
				<FieldErrorFeedback
					errorCode={errorCode}
					defaultValue={errorMessage}
					formatValues={errorValues}
				/>
			</FormGroup>
		);
	}
}

Masked.propTypes = {
	keyId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	mandatory: PropTypes.bool,
	readonly: PropTypes.bool,
	onChange: PropTypes.func,
	onEditEnd: PropTypes.func,
	answer: PropTypes.shape({
		value: PropTypes.string.isRequired,
		valid: PropTypes.bool,
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		// eslint-disable-next-line react/forbid-prop-types
		errorValues: PropTypes.any,
	}),
	tooltip: PropTypes.string,
	maskChar: PropTypes.string,
	mask: PropTypes.string.isRequired,
	alwaysShowMask: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	formatChars: PropTypes.object,
};

Masked.defaultProps = {
	mandatory: false,
	readonly: false,
	onChange: () => undefined,
	onEditEnd: () => undefined,
	answer: { value: '' },
	tooltip: null,
	maskChar: undefined,
	alwaysShowMask: false,
	formatChars: undefined,
};

export default Masked;
