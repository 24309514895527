const extractPathParts = (path, separator = '/') => {
	let workingPath = path;

	if (workingPath.startsWith(separator)) {
		workingPath = workingPath.substring(1);
	}

	if (workingPath.endsWith(separator)) {
		workingPath = workingPath.substring(0, workingPath.length - 1);
	}

	return workingPath.split(separator);
};

export default { extractPathParts };
