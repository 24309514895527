import BaseAnswerHandler from './BaseAnswerHandler';

export default class MultiFileUploadAnswerHandler extends BaseAnswerHandler {
	extractData(answer) {
		const { value } = answer;
		return value.map(v => ({
			id: v.id,
			filename: v.filename,
			content: v.data,
			encoding: 'BASE64',
		}));
	}

	createAnswer(value) {
		if (value.map === undefined) {
			return { value: [] };
		}
		return super.createAnswer(value);
	}
}
