export default {
	MANDATORY_FIELD: {
		errorCode: 'ERROR_LOCAL_MANDATORY_FIELD',
		errorMessage: 'This field is mandatory',
	},
	MAX_LENGTH_EXCEEDED: {
		errorCode: 'ERROR_LOCAL_MAX_LENGTH_EXCEEDED',
		errorMessage: 'length of {value} is more than maximum accepted : {max}',
	},
	MIN_LENGTH_NOT_REACHED: {
		errorCode: 'ERROR_LOCAL_MIN_LENGTH_NOT_REACHED',
		errorMessage: 'length of {value} is less than minimum accepted : {min}',
	},
	NOT_VALID: {
		errorCode: 'ERROR_LOCAL_NOT_VALID',
		errorMessage: 'The value {value} is not valid',
	},
	FORMAT_NOT_MATCHED: {
		errorCode: 'ERROR_LOCAL_FORMAT_NOT_MATCHED',
		errorMessage: 'The value {value} does not match the expected format {format}',
	},
	NOT_VALID_NUMBER: {
		errorCode: 'ERROR_LOCAL_NOT_VALID_NUMBER',
		errorMessage: '{value} is not a valid number',
	},
	MIN_VALUE_NOT_REACHED: {
		errorCode: 'ERROR_LOCAL_MIN_VALUE_NOT_REACHED',
		errorMessage: '{value} is less than minimum value : {min}',
	},
	MAX_VALUE_EXCEEDED: {
		errorCode: 'ERROR_LOCAL_MAX_VALUE_EXCEEDED',
		errorMessage: '{value} is more than maximum value : {max}',
	},
	PASSWORD_NOT_VALID: {
		errorCode: 'ERROR_LOCAL_PASSWORD_NOT_VALID',
		errorMessage:
			'Password should be between {min} and {max} characters long ' +
			'and contain at least a digit, an upper and a lower case character',
	},
	PHONE_NUMBER_NOT_RECOGNIZED: {
		errorCode: 'ERROR_LOCAL_PHONE_NUMBER_NOT_RECOGNIZED',
		errorMessage:
			'{value} does not seem to be a valid phone number. ' +
			'Please specify the country prefix if not present',
	},
	EMPTY_MULTI_OBJECT_CONSTRAINT: {
		errorCode: 'ERROR_LOCAL_EMPTY_MULTI_OBJECT_CONSTRAINT',
		errorMessage:
			'System temporary unavailable. Please contact us (type: {type}, cause: {rootCause})',
	},
	IBAN_FORMAT_NOT_VALID: {
		errorCode: 'IBAN_FORMAT_NOT_VALID',
		errorMessage: 'IBAN format is not valid',
	},
	NO_VALUE_SELECTED: {
		errorCode: 'ERROR_NO_VALUE_SELECTED',
		errorMessage: 'No value has been selected, it is mandatory to select at least one',
	},
	BIC_FORMAT_NOT_VALID: {
		errorCode: 'BIC_FORMAT_NOT_VALID',
		errorMessage: 'BIC format is not valid',
	},
};
