import _ from 'lodash';
import * as Actions from '../actions/answerActions';
import { APPLICATION_LOADED } from '../actions/applicationActions';
import AnswerHandler from '../AnswerHandler';
import MetadataUtils from '../utils/MetadataUtils';

const initialState = {};

const findAnswerPath = (state, level, objectKey, path) => {
	let page;
	let dataGroup;
	let section;
	let field;

	// the path where the endpoint is located, in the format of "pageKey/datagroupKey/sectionKey/fieldKey".
	// the shortest possible is "pageKey" if the argument is specified.
	const split = path ? path.split('/') : [];
	const knownPageKey = split.length > 0 && split[0].length > 0 ? split[0] : null;
	const knownDatagroupKey = split.length > 1 && split[1].length > 0 ? split[1] : null;
	const knownSectionKey = split.length > 2 && split[2].length > 0 ? split[2] : null;

	switch (level) {
		case 'PAGE':
			Object.keys(state).some(pageKey => {
				const p = state[pageKey];
				if (typeof p === 'object') {
					if (pageKey === objectKey) {
						page = pageKey;
					}
				}
				return page !== undefined;
			});
			break;

		case 'SECTION':
			Object.keys(state).some(pageKey => {
				const p = state[pageKey];
				if (typeof p === 'object' && (knownPageKey === null || knownPageKey === pageKey)) {
					Object.keys(p).some(datagroupKey => {
						const d = p[datagroupKey];
						if (
							typeof d === 'object' &&
							(knownDatagroupKey === null || knownDatagroupKey === datagroupKey)
						) {
							Object.keys(d).some(sectionKey => {
								const s = d[sectionKey];
								if (typeof s === 'object') {
									if (sectionKey === objectKey) {
										page = pageKey;
										dataGroup = datagroupKey;
										section = sectionKey;
									}
								}
								return section !== undefined;
							});
						}
						return section !== undefined;
					});
				}
				return section !== undefined;
			});
			break;

		case 'FIELD':
			Object.keys(state).some(pageKey => {
				const p = state[pageKey];
				if (typeof p === 'object' && (knownPageKey === null || knownPageKey === pageKey)) {
					Object.keys(p).some(datagroupKey => {
						const d = p[datagroupKey];
						if (
							typeof d === 'object' &&
							(knownDatagroupKey === null || knownDatagroupKey === datagroupKey)
						) {
							Object.keys(d).some(sectionKey => {
								const s = d[sectionKey];
								if (
									typeof s === 'object' &&
									(knownSectionKey === null || knownSectionKey === sectionKey)
								) {
									Object.keys(s).some(fieldKey => {
										if (fieldKey === objectKey) {
											page = pageKey;
											dataGroup = datagroupKey;
											section = sectionKey;
											field = fieldKey;
										}
										return field !== undefined;
									});
								}
								return field !== undefined;
							});
						}
						return field !== undefined;
					});
				}
				return field !== undefined;
			});
			break;

		default:
			break;
	}

	return {
		page,
		dataGroup,
		section,
		field,
	};
};

const answerReducer = (state = initialState, action = { type: '' }) => {
	switch (action.type) {
		case Actions.RECORD_ANSWER: {
			const {
				page,
				dataGroup,
				section,
				field,
				answer,
				mustValidate,
				fieldInfo,
			} = action.payload;

			const validatedAnswer = mustValidate
				? AnswerHandler.validate(answer, fieldInfo)
				: answer;

			// Need a deep copy (even sub-objects) so Redux notices that they changed
			const newState = _.cloneDeep(state);
			if (!newState[page]) newState[page] = {};
			if (!newState[page][dataGroup]) newState[page][dataGroup] = {};
			if (!newState[page][dataGroup][section]) newState[page][dataGroup][section] = {};
			newState[page][dataGroup][section][field] = validatedAnswer;
			return newState;
		}
		case APPLICATION_LOADED: {
			const answers = state;
			const { pages = {}, context = {} } = action.payload;
			// Load answers
			Object.values(pages).forEach(p => {
				answers[p.key] = answers[p.key] || {};
				if (p.dataGroups) {
					p.dataGroups.forEach(d => {
						if (d.sections) {
							const dgId = d.id;
							answers[p.key][dgId] = answers[p.key][dgId] || {};
							d.sections.forEach(s => {
								answers[p.key][dgId][s.key] = answers[p.key][dgId][s.key] || {};
								if (s.fields) {
									s.fields.forEach(f => {
										let validatedAnswer = null;
										const currentAnswer = answers[p.key][dgId][s.key][f.key];
										if (f.value) {
											const valueAnswer = AnswerHandler.createAnswer(
												f.value,
												f,
												currentAnswer ? currentAnswer.value : currentAnswer,
											);
											validatedAnswer = AnswerHandler.validate(
												valueAnswer,
												f,
												currentAnswer,
											);

											if (f.valid !== undefined && !f.valid) {
												validatedAnswer.valid = false;
												validatedAnswer.errorCode = f.errorCode;
												validatedAnswer.errorMessage = f.error
													? f.error
													: null;
											}
											answers[p.key][dgId][s.key][f.key] = validatedAnswer;
										} else if (currentAnswer) {
											validatedAnswer = AnswerHandler.validate(
												currentAnswer,
												f,
											);
											answers[p.key][dgId][s.key][f.key] = validatedAnswer;
										}
									});
								}
							});
						}
					});
				}
			});
			answers.context = context;
			return answers;
		}
		case Actions.PERSIST_ANSWER:
		case Actions.PERSIST_ANSWER_COMPLETE: {
			const newState = _.cloneDeep(state);
			delete newState.errorMessage;
			return newState;
		}
		case Actions.PERSIST_ANSWER_ERROR: {
			return {
				..._.cloneDeep(state),
				errorMessage: action.payload,
			};
		}
		case Actions.ANSWER_VALIDATION_ERROR: {
			const { errorDetail, path } = action.payload;
			const { level, errorCode, errorMessage, objectKey, objectPath } = errorDetail;
			const { page, dataGroup, section, field } = findAnswerPath(
				state,
				level,
				objectKey,
				path !== undefined && path !== null ? path : objectPath,
			);

			switch (level) {
				case 'PAGE': {
					if (page === undefined) {
						return state;
					}
					// Need a deep copy (even sub-objects) so Redux notices that they changed
					const newState = _.cloneDeep(state);
					const currentPage = newState[page];
					currentPage.valid = false;
					currentPage.errorMessage = errorMessage;
					currentPage.errorCode = `ERROR_REMOTE_${errorCode}`;
					newState[page] = currentPage;
					return newState;
				}
				case 'SECTION': {
					if (section === undefined) {
						return state;
					}
					// Need a deep copy (even sub-objects) so Redux notices that they changed
					const newState = _.cloneDeep(state);
					const currentSection = newState[page][dataGroup][section];
					currentSection.valid = false;
					currentSection.errorMessage = errorMessage;
					currentSection.errorCode = `ERROR_REMOTE_${errorCode}`;
					newState[page][dataGroup][section] = currentSection;
					return newState;
				}
				case 'FIELD': {
					if (field === undefined) {
						return state;
					}
					// Need a deep copy (even sub-objects) so Redux notices that they changed
					const newState = _.cloneDeep(state);
					const currentField = newState[page][dataGroup][section][field];
					currentField.valid = false;
					currentField.errorMessage = errorMessage;
					currentField.errorCode = `ERROR_REMOTE_${errorCode}`;
					newState[page][dataGroup][section][field] = currentField;
					return newState;
				}
				default:
					return state;
			}
		}
		case Actions.READ_OK_RESPONSE: {
			const { path, data } = action.payload;
			if (data.source === 'POST_DOCUMENT') {
				const pageKey = path.split('/')[0];
				const datagroupKey = path.split('/')[1];
				const sectionKey = path.split('/')[2];
				const section = state[pageKey][datagroupKey][sectionKey];
				section.fileGroup.value = data.fileGroup;
				data.files.forEach(file => {
					const subset = section.files.value.filter(
						f => f.filename === file.documentName,
					);
					for (let i = 0; i < subset.length; i += 1) {
						const f = subset[i];
						f.id = file.documentId;
						f.endpoints = file.endpoints;
						Object.values(f.endpoints).forEach(endpoint =>
							MetadataUtils.fixEndpoint(endpoint),
						);
						delete f.data;
						delete f.type;
					}
				});
				const newState = _.cloneDeep(state);
				newState[pageKey][datagroupKey][sectionKey] = section;
				return newState;
			}
			return state;
		}
		case Actions.CLEAR_PAGE_ERRORS: {
			const newState = _.cloneDeep(state);
			Object.entries(newState).forEach(([key, page]) => {
				newState[key] = {
					...page,
					valid: true,
					errorMessage: undefined,
					errorCode: undefined,
				};
			});
			return newState;
		}

		default:
			return state;
	}
};

export default answerReducer;
