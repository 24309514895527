import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import T from '@tunz/translation';

import InfoTip from '../../InfoTip';
import FieldErrorFeedback from '../FieldErrorFeedback';
import SimpleField from '../SimpleField';

class Email extends SimpleField {
	render() {
		const {
			name,
			keyId,
			answer,
			mandatory,
			readonly,
			placeholder,
			tooltip,
			hidesLabel,
		} = this.props;
		const { value, errorMessage, errorCode, errorValues } = answer;

		const valid = this.isValid();
		const invalid = valid === null ? null : !valid;

		return (
			<FormGroup>
				{!hidesLabel && (
					<FormLabel>
						<T>{name}</T>
						{mandatory && <span>*</span>}
						<InfoTip text={tooltip} />
					</FormLabel>
				)}
				<FormControl
					type="email"
					id={keyId}
					placeholder={placeholder}
					onChange={this.recordAnswer}
					onBlur={this.endEditing}
					onKeyUp={this.checkValidity}
					value={value}
					disabled={readonly}
					isValid={valid}
					isInvalid={invalid}
				/>
				<FieldErrorFeedback
					errorCode={errorCode}
					defaultValue={errorMessage}
					formatValues={errorValues}
				/>
			</FormGroup>
		);
	}
}

Email.propTypes = {
	keyId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	mandatory: PropTypes.bool,
	readonly: PropTypes.bool,
	onChange: PropTypes.func,
	onEditEnd: PropTypes.func,
	answer: PropTypes.shape({
		value: PropTypes.string.isRequired,
		valid: PropTypes.bool,
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		// eslint-disable-next-line react/forbid-prop-types
		errorValues: PropTypes.any,
	}),
	placeholder: PropTypes.string,
	tooltip: PropTypes.string,
	hidesLabel: PropTypes.bool,
};

Email.defaultProps = {
	mandatory: false,
	readonly: false,
	onChange: () => undefined,
	onEditEnd: () => undefined,
	answer: { value: '' },
	placeholder: '',
	tooltip: null,
	hidesLabel: false,
};

export default Email;
