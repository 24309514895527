import BaseAnswerHandler from './BaseAnswerHandler';
import Errors from './ValidationErrors';

export default class MultivalueAnswerHandler extends BaseAnswerHandler {
	validate(answer, initialAnswer) {
		if (initialAnswer) {
			this.initialState = initialAnswer;
		}
		const { value } = answer;
		let valid = true;
		let error = null;
		let errorValues = null;
		const mandatory = this.field.requirementLevel === 'REQUIRED';

		if (mandatory && value.length === 0) {
			valid = false;
			error = Errors.MANDATORY_FIELD;
		} else if (!this.field.constraint.values.includes(value)) {
			valid = false;
			error = Errors.NOT_VALID;
			errorValues = { value };
		}
		return {
			value,
			valid,
			...error,
			errorValues,
			...this.validateAgainstInitialState(answer),
		};
	}
}
