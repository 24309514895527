import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, FormLabel } from 'react-bootstrap';
import T from '@tunz/translation';

import answerActions from '../../../actions/answerActions';
import UploadFileManager, { FileItem } from '../../UploadFileManager';
import InfoTip from '../../InfoTip';
import SimpleField from '../SimpleField';

export class MultiFileUpload extends SimpleField {
	render() {
		const {
			name,
			keyId,
			mandatory,
			answer,
			onEditEnd,
			tooltip,
			sendData,
			maxNumberOfFiles,
			maxFileSize,
			acceptedFileTypes,
		} = this.props;
		const { value, errorMessage, errorCode, errorValues } = answer;

		return (
			<FormGroup className="mb-3" controlId={name} ref={this.ref}>
				<FormLabel>
					<T>{name}</T>
					{mandatory && <span>*</span>}
					<InfoTip text={tooltip} />
				</FormLabel>
				<UploadFileManager
					acceptedFileTypes={acceptedFileTypes}
					maxNumberOfFiles={maxNumberOfFiles}
					maxFileSize={maxFileSize}
					onChange={files => onEditEnd(keyId, { value: files })}
					sendData={sendData}
					isValid={this.isValid()}
				>
					{value.map(f => (
						<FileItem key={f.filename} file={f} />
					))}
				</UploadFileManager>
				{errorCode && (
					<Form.Text className="text-danger">
						<T defaultValue={errorMessage} formatValues={errorValues}>
							{errorCode}
						</T>
					</Form.Text>
				)}
			</FormGroup>
		);
	}
}

MultiFileUpload.propTypes = {
	keyId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	mandatory: PropTypes.bool,
	answer: PropTypes.shape({
		// eslint-disable-next-line react/forbid-prop-types
		value: PropTypes.array,
		valid: PropTypes.bool,
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		// eslint-disable-next-line react/forbid-prop-types
		errorValues: PropTypes.any,
	}),
	onEditEnd: PropTypes.func,
	tooltip: PropTypes.string,
	sendData: PropTypes.func,
	maxNumberOfFiles: PropTypes.number,
	maxFileSize: PropTypes.number,
	acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
};

MultiFileUpload.defaultProps = {
	mandatory: false,
	answer: {
		value: [],
		valid: true,
		errorMessage: null,
	},
	onEditEnd: () => undefined,
	tooltip: null,
	sendData: () => undefined,
	maxNumberOfFiles: 12,
	maxFileSize: 15 * 1024 * 1024, // 15mb,
	acceptedFileTypes: [],
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
	sendData: (endpoint, values) => dispatch(answerActions.sendData(endpoint, values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiFileUpload);
