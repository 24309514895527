import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup } from 'react-bootstrap';
import T from '@tunz/translation';

import styles from './BooleanInput.module.scss';
import InfoTip from '../../InfoTip';
import SimpleField from '../SimpleField';

class BooleanInput extends SimpleField {
	toggleValue = () => {
		const { keyId, onEditEnd, answer } = this.props;
		onEditEnd(keyId, { value: !answer.value });
	};

	render = () => {
		const { name, keyId, answer, readonly, tooltip } = this.props;
		const { value, errorMessage } = answer;

		const valid = this.isValid();
		const invalid = valid === null ? null : !valid;

		return (
			<FormGroup controlId={name} className={`mb-3 ${styles.formGroupSize}`}>
				<Form.Check
					type="checkbox"
					onChange={this.toggleValue}
					checked={value}
					name={name}
					id={keyId}
					disabled={readonly}
					label={
						<>
							<T>{name}</T>
							<InfoTip text={tooltip} />
						</>
					}
					isValid={valid}
					isInvalid={invalid}
					feedback={errorMessage}
					feedbackType={invalid ? 'invalid' : undefined}
				/>
			</FormGroup>
		);
	};
}

BooleanInput.propTypes = {
	keyId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	answer: PropTypes.shape({
		value: PropTypes.bool.isRequired,
		valid: PropTypes.bool,
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		// eslint-disable-next-line react/forbid-prop-types
		errorValues: PropTypes.any,
	}),
	onEditEnd: PropTypes.func,
	readonly: PropTypes.bool,
	tooltip: PropTypes.string,
};

BooleanInput.defaultProps = {
	answer: { value: false },
	readonly: false,
	onEditEnd: () => undefined,
	tooltip: null,
};

export default BooleanInput;
