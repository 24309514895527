import React from 'react';
import PropTypes from 'prop-types';
import T from '@tunz/translation';
import { FaSyncAlt } from 'react-icons/fa';

import style from './LoadingIndicator.module.scss';

const LoadingIndicator = ({ translationCode }) => (
	<small>
		<FaSyncAlt className={style.saving} />
		<span className={style.text}>{translationCode && <T>{translationCode}</T>}</span>
	</small>
);

LoadingIndicator.propTypes = {
	translationCode: PropTypes.string,
};

LoadingIndicator.defaultProps = {
	translationCode: undefined,
};

export default LoadingIndicator;
