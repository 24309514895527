import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import T from '@tunz/translation';
import styles from './MultiObject.module.scss';
import InfoTip from '../../InfoTip';
import MultiObjectAnswerHandler from '../../../AnswerHandler/MultiObjectAnswerHandler';
import FieldErrorFeedback from '../FieldErrorFeedback';

class MultiObject extends PureComponent {
	onChange = key => event => {
		const { value } = event.target;
		const { answer } = this.props;
		const updated = answer.map(v =>
			key === v.key
				? {
						...v,
						value,
				  }
				: v,
		);
		this.saveChange(updated);
	};

	onBlur = () => {
		const { keyId, onEditEnd, answer } = this.props;
		onEditEnd(keyId, answer);
	};

	onClick = (index, command) => {
		const { answer } = this.props;
		let updated;
		switch (command) {
			case '+':
				updated = answer.slice(0);
				updated.splice(answer.length + 1, 0, MultiObjectAnswerHandler.generateValue());
				break;

			case '-':
				updated = answer.filter(a => a.key !== answer[index].key);
				break;

			default:
				throw new Error(`Unknown command: ${command}`);
		}
		this.saveChange(updated);
	};

	isValid(key) {
		const { answer } = this.props;
		const value = answer.find(v => key === v.key);
		if (value && value.valid !== undefined) {
			return value.valid;
		}
		return null;
	}

	saveChange(updated) {
		const { keyId, recordAnswer } = this.props;
		recordAnswer(keyId, updated);
	}

	render() {
		const { name, min, max, mandatory, readonly, answer, placeholder, tooltip } = this.props;

		const btnAdd = !readonly ? (
			<Button
				className="ADD_ELEMENT_BTN"
				variant="outline-success"
				size="sm"
				onClick={() => this.onClick(-1, '+')}
			>
				<FaPlusCircle />
			</Button>
		) : null;

		return (
			<Form.Group className={`mb-3 ${name}`}>
				<Form.Label className={styles.labelWithButtons}>
					<T>{name}</T>
					{mandatory && <span>*</span>}
					<InfoTip text={tooltip} />
					{btnAdd}
				</Form.Label>
				{answer.map((v, i) => {
					const btnDelete =
						!readonly && answer.length > 1 ? (
							<Button
								className={`REMOVE_ELEMENT_BTN-${i}`}
								variant="outline-danger"
								onClick={() => this.onClick(i, '-')}
							>
								<FaMinusCircle />
							</Button>
						) : null;
					const {
						key,
						value,
						errorMessage,
						errorCode,
						errorValues,
						enforcedReadonly,
					} = v;

					const valid = this.isValid(key);
					const invalid = valid === null ? null : !valid;

					return (
						<Form.Group className="mb-3" controlId={`${name}-${i}`} key={key}>
							<InputGroup>
								<Form.Control
									type="text"
									placeholder={placeholder}
									onChange={this.onChange(key)}
									onBlur={this.onBlur}
									value={value}
									minLength={min}
									maxLength={max}
									disabled={enforcedReadonly || readonly}
									isValid={valid}
									isInvalid={invalid}
								/>
								{!readonly && answer.length > 1 && btnDelete}
								<FieldErrorFeedback
									errorCode={errorCode}
									formatValues={errorValues}
									defaultValue={errorMessage}
								/>
							</InputGroup>
						</Form.Group>
					);
				})}
			</Form.Group>
		);
	}
}

MultiObject.propTypes = {
	keyId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	min: PropTypes.number,
	max: PropTypes.number,
	answer: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
			value: PropTypes.string.isRequired,
			valid: PropTypes.bool,
			errorMessage: PropTypes.string,
			errorCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			// eslint-disable-next-line react/forbid-prop-types
			errorValues: PropTypes.any,
		}),
	),
	recordAnswer: PropTypes.func,
	onEditEnd: PropTypes.func,
	mandatory: PropTypes.bool,
	readonly: PropTypes.bool,
	placeholder: PropTypes.string,
	tooltip: PropTypes.string,
};

MultiObject.defaultProps = {
	min: 0,
	max: 10000,
	mandatory: false,
	readonly: false,
	recordAnswer: () => undefined,
	onEditEnd: () => undefined,
	answer: [MultiObjectAnswerHandler.generateValue()],
	placeholder: '',
	tooltip: null,
};

export default MultiObject;
