import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { FaChevronRight, FaChevronLeft, FaCheck } from 'react-icons/fa';
import T from '@tunz/translation';

import style from './FormWizard.module.scss';

// /////////////////////////////////////////////////////////////////////////////

class FormWizard extends React.Component {
	static getDerivedStateFromProps = (props, state) => {
		const steps = FormWizard.getStepsKeys(props.children);
		return {
			...state,
			totalSteps: steps.length,
			stepsKeys: steps,
			activeStepIndex: state.activeStepIndex < steps.length ? state.activeStepIndex : 0,
		};
	};

	static getStepsKeys = children => {
		const steps = [];
		children.forEach(child => {
			if (child && child.type && child.type.displayName === 'StepList') {
				React.Children.forEach(
					child.props.children,
					c => {
						steps.push(c.key);
					},
					{},
				);
			}
		});
		return steps;
	};

	constructor(props) {
		super(props);

		this.state = {
			totalSteps: FormWizard.getStepsKeys(props.children).length,
			stepsKeys: FormWizard.getStepsKeys(props.children),
			activeStepIndex: 0,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { activeStepIndex } = this.state;
		const { onStepChange } = this.props;
		if (prevState.activeStepIndex !== activeStepIndex) {
			onStepChange(prevState.activeStepIndex, activeStepIndex);
		}
	}

	render() {
		const { children, onSubmit } = this.props;
		const { activeStepIndex, stepsKeys, totalSteps } = this.state;
		const childrenToRender = React.Children.map(children, child => {
			if (child && child.type && child.type.displayName === 'StepList') {
				return React.cloneElement(child, {
					activeStepIndex,
				});
			}
			if (child && child.type && child.type.displayName === 'StepProgress') {
				return React.cloneElement(child, {
					activeStepIndex,
					steps: stepsKeys,
					goToStep: nexStep =>
						this.setState({
							activeStepIndex: nexStep,
						}),
				});
			}
			if (child && child.type && child.type.displayName === 'ButtonList') {
				return React.cloneElement(child, {
					activeStepIndex,
					totalSteps,
					handleSubmit: onSubmit,
					onNextStep: () =>
						this.setState(state => ({
							activeStepIndex: state.activeStepIndex + 1,
						})),
					onPreviousStep: () =>
						this.setState(state => ({
							activeStepIndex: state.activeStepIndex - 1,
						})),
				});
			}
			return child;
		});

		return <div>{childrenToRender}</div>;
	}
}

FormWizard.propTypes = {
	children: PropTypes.arrayOf(PropTypes.shape()),
	onSubmit: PropTypes.func,
	onStepChange: PropTypes.func,
};

FormWizard.defaultProps = {
	children: [],
	onSubmit: () => undefined,
	onStepChange: () => undefined,
};

// ////////////////////////////////////////////////////////////////////////////

export const StepList = props =>
	React.Children.map(props.children, (child, index) =>
		React.cloneElement(child, {
			isActive: index === props.activeStepIndex,
		}),
	);

StepList.displayName = 'StepList';

export const StepProgress = props =>
	props.children(props.steps, props.activeStepIndex, props.goToStep);

StepProgress.propTypes = {
	children: PropTypes.func.isRequired,
};

StepProgress.displayName = 'StepProgress';

export const ButtonList = props => {
	const {
		activeStepIndex,
		totalSteps,
		onPreviousStep,
		onNextStep,
		handleSubmit,
		className,
		children,
	} = props;
	const isPreviousActive = activeStepIndex > 0;
	const isNextActive = activeStepIndex < totalSteps - 1;
	const isLastStep = activeStepIndex === totalSteps - 1;
	return (
		<div className={`${style.buttonsArea} ${className}`}>
			{React.Children.map(children, child => {
				if (child && child.type && child.type.displayName === 'Previous') {
					return React.cloneElement(child, {
						isPreviousActive,
						goToPreviousStep: onPreviousStep,
					});
				}
				if (child && child.type && child.type.displayName === 'Next') {
					return React.cloneElement(child, {
						isNextActive,
						goToNextStep: onNextStep,
					});
				}
				if (child && child.type && child.type.displayName === 'Submit') {
					return React.cloneElement(child, {
						isLastStep,
						handleSubmit,
					});
				}
				return child;
			})}
		</div>
	);
};
ButtonList.propTypes = {
	children: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	activeStepIndex: PropTypes.number,
	totalSteps: PropTypes.number,
	onPreviousStep: PropTypes.func,
	onNextStep: PropTypes.func,
	handleSubmit: PropTypes.func,
	className: PropTypes.string,
};
ButtonList.defaultProps = {
	activeStepIndex: 0,
	totalSteps: 0,
	onPreviousStep: () => undefined,
	onNextStep: () => undefined,
	handleSubmit: () => undefined,
	className: '',
};
ButtonList.displayName = 'ButtonList';

// /////////////////////////////////////////////////////////////////////////////

export const Step = ({ isActive, render }) => (isActive ? <>{render()}</> : null);

Step.propTypes = {
	isActive: PropTypes.bool,
	render: PropTypes.func,
};
Step.defaultProps = {
	isActive: false,
	render: () => null,
};
Step.displayName = 'Step';

export const Previous = ({ isPreviousActive, goToPreviousStep, waitWhileProcessing }) =>
	isPreviousActive ? (
		<div className={style.buttonLeft}>
			<Button
				id="previous_btn"
				onClick={() => goToPreviousStep()}
				variant="outline-secondary"
				disabled={waitWhileProcessing}
			>
				<FaChevronLeft /> <T>REQ_BACK_BUTTON</T>
			</Button>
		</div>
	) : null;
Previous.propTypes = {
	isPreviousActive: PropTypes.bool,
	goToPreviousStep: PropTypes.func,
	waitWhileProcessing: PropTypes.bool,
};
Previous.defaultProps = {
	isPreviousActive: false,
	goToPreviousStep: () => undefined,
	waitWhileProcessing: false,
};
Previous.displayName = 'Previous';

export const Next = ({ isNextActive, goToNextStep, waitWhileProcessing }) =>
	isNextActive ? (
		<div className={style.buttonRight}>
			<Button
				id="next_btn"
				onClick={() => goToNextStep()}
				variant="outline-secondary"
				disabled={waitWhileProcessing}
			>
				<T>REQ_NEXT_BUTTON</T> <FaChevronRight />
			</Button>
		</div>
	) : null;
Next.propTypes = {
	isNextActive: PropTypes.bool,
	goToNextStep: PropTypes.func,
	waitWhileProcessing: PropTypes.bool,
};
Next.defaultProps = {
	isNextActive: false,
	goToNextStep: () => undefined,
	waitWhileProcessing: false,
};
Next.displayName = 'Next';

export const Submit = ({ isLastStep, handleSubmit, waitWhileProcessing }) =>
	isLastStep ? (
		<div className={style.buttonRight}>
			<Button
				id="finish_btn"
				variant="primary"
				onClick={event => event.preventDefault() || handleSubmit()}
				disabled={waitWhileProcessing}
			>
				<FaCheck /> <T>REQ_FINISH_BUTTON</T>
			</Button>
		</div>
	) : null;

Submit.propTypes = {
	isLastStep: PropTypes.bool,
	handleSubmit: PropTypes.func,
	waitWhileProcessing: PropTypes.bool,
};

Submit.defaultProps = {
	isLastStep: false,
	handleSubmit: () => undefined,
	waitWhileProcessing: false,
};
Submit.displayName = 'Submit';

export default FormWizard;
