import React from 'react';
import { FormCheck, FormGroup, FormLabel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import T from '@tunz/translation';

import styles from './Checkboxes.module.scss';
import InfoTip from '../../InfoTip';
import FieldErrorFeedback from '../FieldErrorFeedback';
import SimpleField from '../SimpleField';

class Checkboxes extends SimpleField {
	save = event => {
		const { keyId, onEditEnd, answer } = this.props;
		const { value } = event.target;
		let answerValue = answer.value;
		if (answerValue.includes(value)) {
			answerValue = answerValue.filter(v => v !== value);
		} else {
			answerValue.push(value);
		}

		onEditEnd(keyId, { value: answerValue });
	};

	render() {
		const { name, values, direction, answer, mandatory, readonly, tooltip } = this.props;
		const { value, errorMessage, errorCode, errorValues } = answer;
		const valid = this.isValid();
		const invalid = valid === null ? null : !valid;

		return (
			<FormGroup className="mb-3" controlId={name}>
				<FormLabel>
					<T>{name}</T>
					{mandatory && <span>*</span>}
					<InfoTip text={tooltip} />
				</FormLabel>
				{values &&
					values.map(v => (
						<span
							key={v}
							className={
								direction === 'VERTICAL'
									? styles.checkboxSpanV
									: styles.checkboxSpanH
							}
						>
							<FormCheck
								key={v}
								id={v}
								name={name}
								label={v}
								onChange={this.save}
								checked={value.includes(v)}
								disabled={readonly}
								isValid={valid}
								isInvalid={invalid}
							/>
						</span>
					))}
				<FieldErrorFeedback
					errorCode={errorCode}
					defaultValue={errorMessage}
					formatValues={errorValues}
				/>
			</FormGroup>
		);
	}
}

Checkboxes.propTypes = {
	keyId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	values: PropTypes.arrayOf(PropTypes.string).isRequired,
	direction: PropTypes.string,
	mandatory: PropTypes.bool,
	readonly: PropTypes.bool,
	answer: PropTypes.shape({
		value: PropTypes.arrayOf(PropTypes.string).isRequired,
		valid: PropTypes.bool,
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		// eslint-disable-next-line react/forbid-prop-types
		errorValues: PropTypes.any,
	}),
	onEditEnd: PropTypes.func,
	tooltip: PropTypes.string,
};

Checkboxes.defaultProps = {
	direction: 'HORIZONTAL',
	mandatory: false,
	readonly: false,
	answer: { value: [] },
	onEditEnd: () => undefined,
	tooltip: null,
};

export default Checkboxes;
