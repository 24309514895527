import { ConstraintUtils } from '../utils/index';
import BaseAnswerHandler from './BaseAnswerHandler';
import Errors from './ValidationErrors';

export default class NumberAnswerHandler extends BaseAnswerHandler {
	parseData(value) {
		return Number.parseFloat(value);
	}

	extractData(answer) {
		const valueToReturn = this.parseData(answer.value);
		return Number.isNaN(valueToReturn) ? '' : valueToReturn;
	}

	createAnswer(value) {
		const parsedValue = this.parseData(value);
		return { value: Number.isNaN(parsedValue) ? '' : parsedValue };
	}

	validate(answer, initialAnswer) {
		if (initialAnswer) {
			this.initialState = initialAnswer;
		}
		const { value } = answer;
		let valid = true;
		let error = null;
		let errorValues = null;
		const mandatory = this.field.requirementLevel === 'REQUIRED';
		const { min, max } = ConstraintUtils.extractIntegerRange(this.field.constraint.range);
		const number = this.parseData(value);

		if (mandatory && value.length === 0) {
			valid = false;
			error = Errors.MANDATORY_FIELD;
		} else {
			const reFloat = /^[+-]?\d+(:?[,.]\d*)?$/;

			if (!reFloat.test(value) || Number.isNaN(number)) {
				valid = false;
				error = Errors.NOT_VALID_NUMBER;
				errorValues = { value };
			} else if (min !== null && number < min) {
				valid = false;
				error = Errors.MIN_VALUE_NOT_REACHED;
				errorValues = {
					value,
					min,
				};
			} else if (max !== null && number > max) {
				valid = false;
				error = Errors.MAX_VALUE_EXCEEDED;
				errorValues = {
					value,
					max,
				};
			}
		}
		return {
			value: Number.isNaN(number) ? '' : number,
			valid,
			...error,
			errorValues,
			...this.validateAgainstInitialState(answer),
		};
	}
}
