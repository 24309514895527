/* eslint-disable jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FaRegFolder, FaRegFolderOpen, FaRegTrashAlt } from 'react-icons/fa';
import T from '@tunz/translation';

import Section from '../Section';
import style from './DataGroup.module.scss';
import MetadataUtils from '../../utils/MetadataUtils';
import AnswerUtils from '../../utils/AnswerUtils';
import BadgeIndicator from '../BadgeIndicator/BadgeIndicator';

class DataGroup extends Component {
	recordAnswerForDataGroup = (section, field, value) => {
		const { id, recordAnswer } = this.props;
		recordAnswer(id, section, field, value);
	};

	persistAnswerForDataGroup = (section, field, value) => {
		const { id, persistAnswer } = this.props;
		persistAnswer(id, section, field, value);
	};

	render() {
		const {
			name,
			sections,
			pageKey,
			answers,
			collapsible,
			collapsed,
			toggleCollapsed,
			remove,
			readonly,
		} = this.props;

		const errorCount = AnswerUtils.numberOfErrors(answers);
		return (
			<Container fluid className={style.dataGroupContainer}>
				{collapsible && (
					<Row className={style.DataGroup}>
						<Col lg={12}>
							<div
								className={[style.expandCol, 'expandDG'].join(' ')}
								onClick={toggleCollapsed}
								role="button"
							>
								<span className={style.dataGroupName}>
									{collapsed ? <FaRegFolder /> : <FaRegFolderOpen />}
									<T>{name}</T>
								</span>
								{!readonly && remove !== undefined && (
									<div className={style.deleteButton}>
										<Button
											onClick={remove}
											variant="link"
											className="REMOVE_BUTTON"
										>
											<FaRegTrashAlt />
										</Button>
									</div>
								)}
								{errorCount > 0 && <BadgeIndicator value={errorCount} />}
							</div>
						</Col>
					</Row>
				)}
				<Row>
					<Col lg={12}>
						{!collapsed &&
							sections
								.sort(MetadataUtils.positionComparator)
								.map(s => (
									<Section
										key={s.key}
										keyId={s.key}
										pageKey={pageKey}
										{...s}
										recordAnswer={this.recordAnswerForDataGroup}
										persistAnswer={this.persistAnswerForDataGroup}
										answers={answers[s.key]}
									/>
								))}
					</Col>
				</Row>
			</Container>
		);
	}
}

export const dataGroupTemplate = PropTypes.shape({
	endpoint: PropTypes.shape({
		method: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
	}),
});

DataGroup.propTypes = {
	name: PropTypes.string,
	id: PropTypes.string.isRequired,
	pageKey: PropTypes.string.isRequired,
	sections: PropTypes.arrayOf(PropTypes.shape({})),
	answers: PropTypes.shape({}),
	collapsible: PropTypes.bool,
	collapsed: PropTypes.bool,
	toggleCollapsed: PropTypes.func,
	remove: PropTypes.func,
	recordAnswer: PropTypes.func,
	persistAnswer: PropTypes.func,
	readonly: PropTypes.bool,
};

DataGroup.defaultProps = {
	name: '',
	sections: [],
	answers: {},
	collapsible: false,
	collapsed: false,
	toggleCollapsed: () => undefined,
	remove: undefined,
	recordAnswer: () => undefined,
	persistAnswer: () => undefined,
	readonly: false,
};

export default DataGroup;
