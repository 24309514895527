import { HttpClient, setAllowedApplicationId } from '@tunz/http';

import TunzError from './TunzError';

setAllowedApplicationId('BOARDING');

const url = 'api/uibootstrap/brandConfig/';

const getConfiguration = async (brand, queryParams, onSuccess, onError) => {
	const fullUrl = url + brand + queryParams;

	try {
		const response = await HttpClient.get(fullUrl, { withCredentials: true });
		if (response.status === 200) {
			onSuccess(response.data);
		} else {
			onError(new TunzError('CONFIG_ERROR_CONTACT_SERVER', 'Error contacting the server'));
		}
	} catch (e) {
		onError(e);
	}
};

export default { getConfiguration };
