/* eslint-disable class-methods-use-this */

export default class BaseAnswerHandler {
	constructor(field) {
		this.field = field;
		this.initialState = this.buildInitialState();
	}

	validate(answer, initialAnswer) {
		if (initialAnswer) {
			this.initialState = initialAnswer;
		}
		return {
			...answer,
			valid: true,
			...this.validateAgainstInitialState(answer),
		};
	}

	extractData(answer) {
		return answer.value;
	}

	createAnswer(value) {
		return { value };
	}

	isValid(answer) {
		return answer.valid !== undefined && answer.valid;
	}

	/**
	 *
	 * @param [field]
	 *    required for multi object
	 * @param [customValue]
	 *    required for multi object     *
	 * @returns {{valid: *, errorCode: *, errorMessage: *, value: *}}
	 */
	buildInitialState(field, customValue) {
		const f = field || this.field;
		return {
			valid: f.valid,
			errorCode: f.errorCode,
			errorMessage: f.error,
			value: customValue || f.value,
		};
	}

	validateAgainstInitialState(current) {
		const initialState = this.initialState || { valid: true };
		return this.doValidateAgainstInitialState(current, initialState);
	}

	doValidateAgainstInitialState(current, initialState) {
		return initialState.valid !== undefined &&
			!initialState.valid &&
			// back to initial, rejected value ?
			this.createAnswer(initialState.value).value === this.createAnswer(current.value).value
			? {
					valid: false,
					errorCode: initialState.errorCode,
					errorMessage: initialState.errorMessage,
			  }
			: {};
	}
}
