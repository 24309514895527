/* eslint-disable class-methods-use-this */
import shortid from 'shortid';
import { ConstraintUtils } from '../utils';
import BaseAnswerHandler from './BaseAnswerHandler';
import StringAnswerHandler from './StringAnswerHandler';
import ServerErrors from '../api/ServerErrors';

export default class MultiObjectAnswerHandler extends BaseAnswerHandler {
	static generateValue = (value = '') => ({
		key: shortid.generate(),
		value,
	});

	constructor(field) {
		super(field);

		const innerKeys = ConstraintUtils.extractMultiObjectInnerKeys(field.constraint);
		const index = 0; // we assume this contain at least and only one element
		this.fieldKey = innerKeys[index]; // validate ?

		/* assume there is (at least?) one field !
		  - type: ${field.constraint[fieldKey].type}
		  - length: ${field.constraint[fieldKey].length}`);
		 */
		const innerField = {
			requirementLevel: field.requirementLevel, // inherited from parent
			constraint: field.constraint[this.fieldKey], //
		};

		if (innerField.constraint.type.toLowerCase() === 'string') {
			this.handler = new StringAnswerHandler(innerField);
		} else {
			this.handler = new BaseAnswerHandler(innerField);
		}

		if (field.value && typeof field.value.map === 'function') {
			this.initialStates = field.value.map(e => ({
				id: e.id,
				...this.buildInitialState(e, e[this.fieldKey]),
			}));
		}
	}

	validate(answer) {
		return answer.map(a => {
			const validatedAnswer = this.handler.validate(a);
			return {
				...a,
				...validatedAnswer,
				...this.validateAgainstInitialState(a),
			};
		});
	}

	/**
	 * format what is sent to the API
	 * @returns {*}
	 */
	extractData(answer) {
		return answer.map(a => ({
			id: a.id,
			[this.fieldKey]: a.value,
		}));
	}

	/**
	 *
	 * @param value array of objects
	 * @returns {*}
	 */
	createAnswer(value) {
		if (!value) {
			return [MultiObjectAnswerHandler.generateValue()];
		}
		if (typeof value.map === 'function') {
			return value.map(e => ({
				...e,
				key: e.id,
				value: e[this.fieldKey],
				...this.validateAgainstInitialState(e),
			}));
		}

		return [MultiObjectAnswerHandler.generateValue(value)];
	}

	validateAgainstInitialState(current) {
		let result = {};
		const initialStates = this.initialStates || [];
		const matches = initialStates.filter(e => e.id === current.id);
		if (matches.length > 0) {
			const res = this.handler.doValidateAgainstInitialState(current, matches[0]);
			if (res.valid === false) {
				result = res;
			}
			if (current.errorCode === ServerErrors.DELETION_PROPOSAL_REJECTED) {
				// this is not a validation error as such
				result.valid = true;
				result.enforcedReadonly = true;
			}
		}
		return result;
	}

	isValid(answer) {
		const nonValidAnswers = answer.filter(a => !this.handler.isValid(a));
		return nonValidAnswers.length === 0;
	}
}
