import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import T from '@tunz/translation';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import es from 'react-phone-input-2/lang/es.json';
import fr from 'react-phone-input-2/lang/fr.json';
import de from 'react-phone-input-2/lang/de.json';
import InfoTip from '../../InfoTip';
import style from './PhoneNumber.module.scss';
import FieldErrorFeedback from '../FieldErrorFeedback';
import SimpleField from '../SimpleField';

class PhoneNumber extends SimpleField {
	recordAnswer = data => {
		const value = PhoneNumber.PHONE_PREFIX + data;
		const { keyId, onChange } = this.props;
		onChange(keyId, { value });
	};

	getLocalization = lang => {
		switch (lang.toLowerCase()) {
			case 'de':
				return de;
			case 'es':
				return es;
			case 'fr':
				return fr;
			default:
				return 'en';
		}
	};

	render() {
		const {
			keyId,
			name,
			answer,
			mandatory,
			tooltip,
			placeholder,
			currentLanguage,
			defaultCountry,
			readonly,
		} = this.props;
		const { value, errorMessage, errorCode, errorValues } = answer;
		const valid = this.isValid();
		const invalid = valid === null ? null : !valid;
		const lang = currentLanguage.split('-')[0].toLowerCase();
		const localization = this.getLocalization(lang);
		let cssClass = `${style.phoneNumberInput}`;
		if (valid) {
			cssClass += ' is-valid';
		} else if (invalid) {
			cssClass += ' is-invalid';
		}
		const forceToShow = ' d-block';
		return (
			<FormGroup>
				<FormLabel>
					<T>{name}</T>
					{mandatory && <span>*</span>}
					<InfoTip text={tooltip} />
				</FormLabel>
				<PhoneInput
					inputProps={{
						id: keyId,
					}}
					placeholder={placeholder}
					value={value}
					country={defaultCountry.toLowerCase()}
					localization={localization}
					inputClass={cssClass}
					disabled={readonly}
					onChange={data => {
						this.recordAnswer(data);
					}}
					onBlur={this.endEditing}
				/>
				<FieldErrorFeedback
					errorCode={errorCode}
					defaultValue={errorMessage}
					formatValues={errorValues}
					className={forceToShow}
				/>
				<FormControl.Feedback type="invalid" />
			</FormGroup>
		);
	}
}

PhoneNumber.PHONE_PREFIX = '+';

PhoneNumber.propTypes = {
	keyId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	onEditEnd: PropTypes.func,
	answer: PropTypes.shape({
		value: PropTypes.string,
		country: PropTypes.string,
		defaultCountry: PropTypes.string,
		valid: PropTypes.bool,
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		// eslint-disable-next-line react/forbid-prop-types
		errorValues: PropTypes.any,
	}),
	mandatory: PropTypes.bool,
	readonly: PropTypes.bool,
	placeholder: PropTypes.string,
	tooltip: PropTypes.string,
	defaultCountry: PropTypes.string,
	currentLanguage: PropTypes.string,
};

PhoneNumber.defaultProps = {
	mandatory: false,
	readonly: false,
	onChange: () => undefined,
	onEditEnd: () => undefined,
	answer: { value: '' },
	placeholder: '',
	tooltip: null,
	defaultCountry: 'be',
	currentLanguage: 'en-US',
};

export default PhoneNumber;
