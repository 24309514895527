import { combineReducers } from 'redux';
import applicationReducer from './applicationReducer';
import answerReducer from './answerReducer';
import configurationReducer from './configurationReducer';
import userReducer from './userReducer/index';

export default combineReducers({
	application: applicationReducer,
	answers: answerReducer,
	config: configurationReducer,
	user: userReducer,
});
