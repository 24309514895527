import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import T from '@tunz/translation';

import DataGroup, { dataGroupTemplate } from '../DataGroup';
import style from './Page.module.scss';
import MetadataUtils from '../../utils/MetadataUtils';
import LoadingIndicator from '../LoadingIndicator';

const SimplePage = ({
	dataGroups,
	name,
	keyId,
	answers,
	valid,
	error,
	isSaving,
	recordAnswer,
	persistAnswer,
}) => {
	const { errorValues } = answers;
	const isAnswerValid = answers.valid === undefined || answers.valid;

	const isValid = valid && isAnswerValid;
	const errorMessage = answers.errorMessage || error;
	const errorCode = answers.errorCode || error;

	return (
		<Container fluid>
			<Row>
				<Col lg={12} className={!valid ? style.error : null}>
					<h2 className={`mt-4 ${style.title}`}>
						<T>{name}</T>
						{!isValid && (
							<small>
								<T defaultValue={errorMessage} formatValues={errorValues}>
									{errorCode}
								</T>
							</small>
						)}
						{isSaving && (
							<div className="float-end">
								<LoadingIndicator />
							</div>
						)}
					</h2>
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					{dataGroups.sort(MetadataUtils.positionComparator).map(d => {
						const dgId = d.id;
						return (
							<DataGroup
								key={dgId}
								id={dgId}
								pageKey={keyId}
								sections={d.sections}
								answers={answers[dgId]}
								recordAnswer={recordAnswer}
								persistAnswer={persistAnswer}
							/>
						);
					})}
				</Col>
			</Row>
		</Container>
	);
};

SimplePage.propTypes = {
	name: PropTypes.string.isRequired,
	keyId: PropTypes.string.isRequired,
	valid: PropTypes.bool,
	error: PropTypes.string,
	isSaving: PropTypes.bool,
	dataGroups: PropTypes.arrayOf(dataGroupTemplate),
	answers: PropTypes.shape({
		valid: PropTypes.bool,
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		errorValues: PropTypes.shape({}),
	}),
	recordAnswer: PropTypes.func,
	persistAnswer: PropTypes.func,
};

SimplePage.defaultProps = {
	valid: true,
	error: null,
	isSaving: false,
	dataGroups: [],
	answers: {},
	recordAnswer: () => undefined,
	persistAnswer: () => undefined,
};

export default SimplePage;
