import { sendBoardingLinkRequest } from '../api/BoardingApi';

export const OPEN_BOARDING_LINK = 'USER/OPEN_BOARDING_LINK';
export const CLOSE_BOARDING_LINK = 'USER/CLOSE_BOARDING_LINK';
export const SEND_BOARDING_LINK_REQUEST = 'USER/SEND_BOARDING_LINK_REQUEST';
export const SEND_BOARDING_LINK_SUCCESS = 'USER/SEND_BOARDING_LINK_SUCCESS';
export const SEND_BOARDING_LINK_FAILED = 'USER/SEND_BOARDING_LINK_FAILED';

const openBoardingLink = () => ({
	type: OPEN_BOARDING_LINK,
});

const closeBoardingLink = () => ({
	type: CLOSE_BOARDING_LINK,
});

const requestBoardingLink = email => async dispatch => {
	dispatch({
		type: SEND_BOARDING_LINK_REQUEST,
	});

	try {
		await sendBoardingLinkRequest(email);
		dispatch({ type: SEND_BOARDING_LINK_SUCCESS });
	} catch (error) {
		dispatch({
			type: SEND_BOARDING_LINK_FAILED,
			payload: error.message,
		});
	}
};

export default {
	openBoardingLink,
	closeBoardingLink,
	requestBoardingLink,
};
