import React from 'react';
import PropTypes from 'prop-types';
import { FaCheckCircle, FaCircle } from 'react-icons/fa';
import T from '@tunz/translation';

import style from './Flow.module.scss';

const getClassNameForStep = (currentStep, i) => {
	let className = `${style.done} done`;
	if (currentStep === i) {
		className = `${style.current} current`;
	}
	if (currentStep < i) {
		className = `${style.todo} todo`;
	}
	return `${style.FlowStep} ${className}`;
};

const Flow = ({ steps, currentStep }) => (
	<div className={`${style.Flow} Flow`}>
		<h4 className={`${style.title} flow-title`}>
			<T>BOARDING_FLOW_TITLE</T>
		</h4>
		<div className={`${style.FlowSteps} flow-steps`}>
			{steps.map((s, i) => (
				<div className={getClassNameForStep(currentStep, i)} key={s.name}>
					<div className={`${style.icon} icon`}>
						{i < currentStep ? (
							<FaCheckCircle className={`${style.circle} circle`} />
						) : (
							<FaCircle className={`${style.circle} circle`} />
						)}
					</div>
					<div className={style.Name}>
						<span>
							<T>{s.name}</T>
						</span>
					</div>
				</div>
			))}
		</div>
	</div>
);

Flow.propTypes = {
	steps: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
		}),
	).isRequired,
	currentStep: PropTypes.number.isRequired,
};

export default Flow;
