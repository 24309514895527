import React from 'react';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { TranslationContext } from '@tunz/translation';

import style from './InfoTip.module.scss';

const InfoTip = ({ text }) => {
	if (text === null) {
		return null;
	}

	const renderTooltip = translatedText => props => (
		<Tooltip id={`toolip-${text}`} {...props}>
			{translatedText}
		</Tooltip>
	);

	return (
		<TranslationContext.Consumer>
			{({ getTranslation }) => {
				const translatedText = getTranslation(text);
				if (translatedText === null) {
					return null;
				}
				return (
					<OverlayTrigger
						placement="right"
						delay={{ show: 250, hide: 400 }}
						overlay={renderTooltip(translatedText)}
					>
						<Button variant="link" className={style.tip} tabIndex={-1} size="sm">
							<FaInfoCircle />
						</Button>
					</OverlayTrigger>
				);
			}}
		</TranslationContext.Consumer>
	);
};

InfoTip.propTypes = {
	text: PropTypes.string,
};
InfoTip.defaultProps = {
	text: null,
};

export default InfoTip;
