import BaseAnswerHandler from './BaseAnswerHandler';
import Errors from './ValidationErrors';

export default class EmailAnswerHandler extends BaseAnswerHandler {
	// eslint-disable-next-line
	static re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i;

	static validateEmail(email) {
		return EmailAnswerHandler.re.test(String(email));
	}

	validate(answer, initialAnswer) {
		if (initialAnswer) {
			this.initialState = initialAnswer;
		}
		const { value } = answer;
		let valid = true;
		let error = null;
		let errorValues = null;

		const mandatory = this.field.requirementLevel === 'REQUIRED';

		if (mandatory && value.length === 0) {
			valid = false;
			error = Errors.MANDATORY_FIELD;
		} else if (!EmailAnswerHandler.validateEmail(value) && value.length > 0) {
			valid = false;
			error = Errors.FORMAT_NOT_MATCHED;
			errorValues = { value };
		}
		return {
			value,
			valid,
			...error,
			errorValues,
			...this.validateAgainstInitialState(answer),
		};
	}
}
