import BaseAnswerHandler from './BaseAnswerHandler';
import Errors from './ValidationErrors';

const min = 8;
const max = 32;

export default class PasswordAnswerHandler extends BaseAnswerHandler {
	// eslint-disable-next-line prefer-template,no-useless-escape
	static regex = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{' + min + ',' + max + '}$');

	validate(answer, initialAnswer) {
		if (initialAnswer) {
			this.initialState = initialAnswer;
		}
		const { value } = answer;
		const mandatory = this.field.requirementLevel === 'REQUIRED';

		let valid = true;
		let error = null;
		let errorValues = null;

		if (mandatory && value.length === 0) {
			valid = false;
			error = Errors.MANDATORY_FIELD;
		} else if (!PasswordAnswerHandler.regex.test(value)) {
			valid = false;
			error = Errors.PASSWORD_NOT_VALID;
			errorValues = {
				min,
				max,
			};
		}
		return {
			value,
			valid,
			...error,
			errorValues,
			...this.validateAgainstInitialState(answer),
		};
	}
}
