import IBAN from 'iban';
import BaseAnswerHandler from './BaseAnswerHandler';
import Errors from './ValidationErrors';

class IbanAnswerHandler extends BaseAnswerHandler {
	validate(answer, initialAnswer) {
		if (initialAnswer) {
			this.initialState = initialAnswer;
		}
		let { value } = answer;
		let valid = true;
		let error = null;
		let errorValues = null;
		const mandatory = this.field.requirementLevel === 'REQUIRED';

		if (!IBAN.isValid(value)) {
			valid = false;
			error = Errors.IBAN_FORMAT_NOT_VALID;
			errorValues = value;
		} else {
			value = IBAN.printFormat(value, ' ');
		}

		if (mandatory && value.length === 0) {
			valid = false;
			error = Errors.MANDATORY_FIELD;
		}

		return {
			value,
			valid,
			...error,
			errorValues,
			...this.validateAgainstInitialState(answer),
		};
	}

	extractData(answer) {
		const { value } = answer;
		try {
			return IBAN.electronicFormat(value);
		} catch (e) {
			return value;
		}
	}
}

export default IbanAnswerHandler;
