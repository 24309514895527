import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

// eslint-disable-next-line no-underscore-dangle
const enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
	: compose;

export default createStore(reducers, compose(applyMiddleware(thunk), enhancer));
