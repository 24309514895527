export const WARNING_THRESHOLD = 5 * 60; // 5 minutes
export const ON_TIME_THRESHOLD = 0; // 0 minute

export const ALERTS = {
	RESET: 'RESET',
	WARNING: 'WARNING',
	ON_TIME: 'ON_TIME',
	TIME_LEFT: 'TIME_LEFT',
};

let token;
let warningTimeout;
let onTimeTimeout;
let timeLeftInterval;

const callbacks = {
	RESET: [],
	WARNING: [],
	ON_TIME: [],
	TIME_LEFT: [],
};

const parse = t => {
	const body = t.split('.')[1];
	return JSON.parse(atob(body));
};

const getExpiration = t => {
	if (t !== undefined) {
		const content = parse(t);
		return content.exp;
	}
	return 0;
};

const getRemainingTime = t => {
	if (t !== undefined) {
		const expiration = getExpiration(token);
		const currentTime = Math.floor(Date.now() / 1000);
		return expiration - currentTime;
	}
	return 0;
};

const triggerCallbacks = (callbackList, level, info) => () => {
	callbackList.forEach(c => c(level, info));
};

const handleTimeLeftInterval = () => {
	if (token !== undefined) {
		const remainingTime = getRemainingTime(token);
		if (remainingTime > 0) {
			triggerCallbacks(callbacks.TIME_LEFT, ALERTS.TIME_LEFT, remainingTime)();
		}
	}
};

const resetTimeouts = () => {
	const timeLeft = getRemainingTime(token);

	triggerCallbacks(callbacks.RESET, ALERTS.RESET)();

	if (warningTimeout !== undefined) {
		clearTimeout(warningTimeout);
	}
	warningTimeout = setTimeout(
		triggerCallbacks(callbacks.WARNING, ALERTS.WARNING, WARNING_THRESHOLD),
		(timeLeft - WARNING_THRESHOLD) * 1000,
	);

	if (onTimeTimeout !== undefined) {
		clearTimeout(onTimeTimeout);
	}
	onTimeTimeout = setTimeout(
		triggerCallbacks(callbacks.ON_TIME, ALERTS.ON_TIME, ON_TIME_THRESHOLD),
		(timeLeft - ON_TIME_THRESHOLD) * 1000,
	);
};

const recordToken = t => {
	token = t;
	resetTimeouts();
};

const getToken = () => token;

const attachCB = (level, cb) => {
	switch (level) {
		case ALERTS.RESET:
			callbacks.RESET.push(cb);
			break;
		case ALERTS.WARNING:
			callbacks.WARNING.push(cb);
			break;
		case ALERTS.ON_TIME:
			callbacks.ON_TIME.push(cb);
			break;
		case ALERTS.TIME_LEFT:
			callbacks.TIME_LEFT.push(cb);
			break;
		default:
			console.warn(`No THRESHOLD found for ${level}`);
	}

	if (callbacks.TIME_LEFT.length > 0 && timeLeftInterval === undefined) {
		timeLeftInterval = setInterval(handleTimeLeftInterval, 1000);
	}
};

export default {
	recordToken,
	getToken,
	attachCB,
};
