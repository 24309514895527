import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import T from '@tunz/translation';

import style from './ErrorDisplay.module.scss';

const ErrorDisplay = ({ error }) => (
	<div className={style.errorMessage}>
		<Container>
			<Row className="justify-content-md-center">
				<Col md={6}>
					<Alert variant="danger">
						<div>
							<strong>
								<T>ERROR_TITLE</T>
							</strong>
						</div>
						<T defaultValue={error.message} formatValues={error.values}>
							{error.code}
						</T>
					</Alert>
				</Col>
			</Row>
		</Container>
	</div>
);

ErrorDisplay.propTypes = {
	error: PropTypes.instanceOf(Error).isRequired,
};

export default ErrorDisplay;
