import BaseAnswerHandler from './BaseAnswerHandler';
import Errors from './ValidationErrors';

export default class DateAnswerHandler extends BaseAnswerHandler {
	static regex = /^\d{4}-\d{2}-\d{2}$/;

	validate(answer, initialAnswer) {
		if (initialAnswer) {
			this.initialState = initialAnswer;
		}
		const { value } = answer;
		const mandatory = this.field.requirementLevel === 'REQUIRED';

		let valid = true;
		let error = null;
		let errorValues = null;

		if (mandatory && value.length === 0) {
			valid = false;
			error = Errors.MANDATORY_FIELD;
		} else if (value.length > 0 && !DateAnswerHandler.regex.test(value)) {
			valid = false;
			error = Errors.FORMAT_NOT_MATCHED;
			errorValues = {
				value,
				format: 'yyyy-mm-dd',
			};
		}
		return {
			value,
			valid,
			...error,
			errorValues,
			...this.validateAgainstInitialState(answer),
		};
	}
}
