import StringHandler from './StringAnswerHandler';
import DateHandler from './DateAnswerHandler';
import EmailHandler from './EmailAnswerHandler';
import BooleanHandler from './BooleanAnswerHandler';
import CheckboxHandler from './CheckboxAnswerHandler';
import MultiValueHandler from './MultivalueAnswerHandler';
import NumberHandler from './NumberAnswerHandler';
import PasswordHandler from './PasswordAnswerHandler';
import MultiStringHandler from './MultiStringAnswerHandler';
import MultiObjectAnswerHandler from './MultiObjectAnswerHandler';
import BaseAnswerHandler from './BaseAnswerHandler';
import MultiFileUploadAnswerHandler from './MultiFileUploadAnswerHandler';
import PhoneNumberAnswerHandler from './PhoneNumberAnswerHandler';
import IbanAnswerHandler from './IbanAnswerHandler';
import MultiSelectionAnswerHandler from './MultiSelectionAnswerHandler';
import IntegerAnswerHandler from './IntegerAnswerHandler';
import BicAnswerHandler from './BicAnswerHandler';
import RegexBoundedAnswerHandler from './RegexBoundedAnswerHandler';
import ComboBoxAnswerHandler from './ComboBoxAnswerHandler';

const currentHandlers = {};

const createHandler = field => {
	if (!field || !field.constraint || !field.constraint.type) {
		return new BaseAnswerHandler();
	}
	switch (field.constraint.type.toLowerCase()) {
		case 'string':
		case 'freetext':
		case 'text':
			return new StringHandler(field);
		case 'telephone_number':
			return new PhoneNumberAnswerHandler(field);
		case 'boolean':
			return new BooleanHandler(field);
		case 'password':
			return new PasswordHandler(field);
		case 'number':
			return new NumberHandler(field);
		case 'integer':
			return new IntegerAnswerHandler(field);
		case 'enum':
		case 'enum_label':
		case 'radio':
			return new MultiValueHandler(field);
		case 'multienum':
			return new CheckboxHandler(field);
		case 'date':
			return new DateHandler(field);
		case 'email':
			return new EmailHandler(field);
		case 'multi_string':
			return new MultiStringHandler(field);
		case 'multi_object':
			return new MultiObjectAnswerHandler(field);
		case 'multi_doc_file':
			return new MultiFileUploadAnswerHandler(field);
		case 'multi_selection':
			return new MultiSelectionAnswerHandler(field);
		case 'iban':
			return new IbanAnswerHandler(field);
		case 'bic':
			return new BicAnswerHandler(field);
		case 'regex':
		case 'masked':
			return new RegexBoundedAnswerHandler(field);
		case 'combo_box':
			return new ComboBoxAnswerHandler(field);
		default:
			return new BaseAnswerHandler(field);
	}
};

const getHandler = field => {
	if (currentHandlers[field.path] === undefined) {
		currentHandlers[field.path] = createHandler(field);
	}
	return currentHandlers[field.path];
};

const validate = (answer, field, initialAnswer) => {
	const handler = getHandler(field);
	return handler.validate(answer, initialAnswer);
};

const getDataToSave = (answer, field) => {
	const handler = getHandler(field);
	return handler.extractData(answer);
};

const createAnswer = (value, field, previousValue) => {
	const handler = getHandler(field);
	return handler.createAnswer(value, previousValue);
};

const isValid = (answer, field) => {
	const handler = getHandler(field);
	return handler.isValid(handler.validate(answer));
};

export default {
	validate,
	getDataToSave,
	createAnswer,
	isValid,
};
