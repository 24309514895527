import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Col, Container, Row } from 'react-bootstrap';
import T, { TranslationContext } from '@tunz/translation';

import ConfigurationActions from '../../actions/configurationActions';
import UserActions from '../../actions/userActions';
import style from './WelcomePage.module.scss';
import BoardingLinkRequestor from './BoardingLinkRequestor';

export const WelcomePage = ({
	createMerchant,
	isGetBoardingLinkOpen,
	requestBoardingLink,
	boardingLinkRequestSuccessful,
	openBoardingLink,
	closeBoardingLink,
	isGetBoardingLinkLoading,
	boardingLinkRequestFailed,
	boardingLinkRequestErrorMessage,
	config,
}) => {
	const { currentLanguage } = useContext(TranslationContext);
	const country = currentLanguage.split('-')[1].toLowerCase();
	const merchantCreationAllowed = config['merchant.creation.allowed'];
	const translationValues = {
		phone: config[`help_phone_${country}`] || config.help_phone,
		mail: config[`help_email_${country}`] || config.help_email,
		brand_name: config.brand_name || 'Worldline',
	};

	return (
		<Container className={style.welcomeTitle}>
			<Row className="show-Container">
				<Col md={12}>
					<div className={`container-fluid bg-light text-dark p-5 ${style.welcomeArea}`}>
						<div className="container bg-light p-5">
							<h1>
								<T formatValues={translationValues}>WELCOME_TITLE</T>
							</h1>
							<p>
								<T>WELCOME_TEXT</T>
							</p>
							<p>
								{merchantCreationAllowed === 'true' ? (
									<Button
										size="lg"
										className={style.startButton}
										onClick={createMerchant}
										id="WELCOME_START_BOARDING_BUTTON"
									>
										<T>WELCOME_START_BOARDING_BUTTON</T>
									</Button>
								) : (
									<T formatValues={translationValues}>CONTACT_MKP_OPERATOR</T>
								)}
							</p>
							<BoardingLinkRequestor
								isOpen={isGetBoardingLinkOpen}
								requestLink={requestBoardingLink}
								isRequestSuccessful={boardingLinkRequestSuccessful}
								open={openBoardingLink}
								close={closeBoardingLink}
								isLoading={isGetBoardingLinkLoading}
								isRequestFail={boardingLinkRequestFailed}
								requestFailDetails={boardingLinkRequestErrorMessage}
							/>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

WelcomePage.propTypes = {
	createMerchant: PropTypes.func,
	isGetBoardingLinkOpen: PropTypes.bool,
	isGetBoardingLinkLoading: PropTypes.bool,
	requestBoardingLink: PropTypes.func,
	boardingLinkRequestSuccessful: PropTypes.bool,
	boardingLinkRequestFailed: PropTypes.bool,
	boardingLinkRequestErrorMessage: PropTypes.string,
	openBoardingLink: PropTypes.func,
	closeBoardingLink: PropTypes.func,
	config: PropTypes.shape(),
};

WelcomePage.defaultProps = {
	createMerchant: () => undefined,
	isGetBoardingLinkOpen: false,
	isGetBoardingLinkLoading: false,
	requestBoardingLink: () => undefined,
	openBoardingLink: () => undefined,
	closeBoardingLink: () => undefined,
	boardingLinkRequestSuccessful: false,
	boardingLinkRequestFailed: false,
	boardingLinkRequestErrorMessage: null,
	config: {},
};

WelcomePage.displayName = 'WelcomePage';

const mapStateToProps = state => ({
	isGetBoardingLinkOpen: state.user.getBoardingLink.open,
	isGetBoardingLinkLoading: state.user.getBoardingLink.loading,
	boardingLinkRequestSucceeded: state.user.getBoardingLink.succeeded,
	boardingLinkRequestFailed: state.user.getBoardingLink.error,
	boardingLinkRequestErrorMessage: state.user.getBoardingLink.errorMessage,
	config: state.config,
});

const mapDispatchToProps = dispatch => ({
	createMerchant: () => dispatch(ConfigurationActions.createNewMerchant()),
	requestBoardingLink: email => dispatch(UserActions.requestBoardingLink(email)),
	openBoardingLink: () => dispatch(UserActions.openBoardingLink()),
	closeBoardingLink: () => dispatch(UserActions.closeBoardingLink()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
