import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { FormGroup, FormLabel } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import T from '@tunz/translation';

import InfoTip from '../../InfoTip';
import FieldErrorFeedback from '../FieldErrorFeedback';
import SimpleField from '../SimpleField';

class DatePickerInput extends SimpleField {
	save = selectedDate => {
		const value = selectedDate.toISOString().split('T')[0];
		const { keyId, onEditEnd } = this.props;
		onEditEnd(keyId, { value });
	};

	toDate = potentialDateString => {
		if (potentialDateString && potentialDateString.length > 0) {
			return new Date(potentialDateString);
		}
		return undefined;
	};

	getClassName = () => {
		const valid = this.isValid();
		const invalid = valid === null ? null : !valid;

		let className = 'form-control';
		if (valid) {
			className += ' is-valid';
		} else if (invalid) {
			className += ' is-invalid';
		}
		return className;
	};

	render() {
		const {
			name,
			keyId,
			answer,
			mandatory,
			readonly,
			placeholder,
			tooltip,
			minDate,
			maxDate,
			format,
		} = this.props;
		const { value, errorMessage, errorCode, errorValues } = answer;
		const className = this.getClassName();
		return (
			<FormGroup className="mb-3" controlId={name}>
				<FormLabel>
					<T>{name}</T>
					{mandatory && <span>*</span>}
					<InfoTip text={tooltip} />
				</FormLabel>
				<div>
					<DatePicker
						className={className}
						id={keyId}
						name={name}
						onChange={this.save}
						dateFormat={format}
						selected={this.toDate(value)}
						peekNextMonth
						showMonthDropdown
						showYearDropdown
						dropdownMode="select"
						autoComplete="off"
						disabled={readonly}
						placeholderText={placeholder}
						minDate={this.toDate(minDate)}
						maxDate={this.toDate(maxDate)}
					/>
				</div>
				<FieldErrorFeedback
					errorCode={errorCode}
					defaultValue={errorMessage}
					formatValues={errorValues}
				/>
			</FormGroup>
		);
	}
}

DatePickerInput.propTypes = {
	keyId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	mandatory: PropTypes.bool,
	readonly: PropTypes.bool,
	answer: PropTypes.shape({
		value: PropTypes.string.isRequired,
		valid: PropTypes.bool,
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		// eslint-disable-next-line react/forbid-prop-types
		errorValues: PropTypes.any,
	}),
	minDate: PropTypes.string,
	maxDate: PropTypes.string,
	onEditEnd: PropTypes.func,
	placeholder: PropTypes.string,
	tooltip: PropTypes.string,
	format: PropTypes.string,
};

DatePickerInput.defaultProps = {
	mandatory: false,
	readonly: false,
	answer: { value: '' },
	onEditEnd: () => undefined,
	placeholder: '',
	tooltip: null,
	minDate: undefined,
	maxDate: undefined,
	format: 'yyyy-MM-dd',
};

export default DatePickerInput;
