import React, { useContext } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { TranslationContext } from '@tunz/translation';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const getOptions = list => {
	const items = [];
	Object.entries(list).forEach(key => items.push({ code: key[0], label: key[1] }));
	return items;
};

const ComboBox = ({ allowedValues, name, answer, keyId, onChange, onEditEnd, mandatory }) => {
	const { getTranslation } = useContext(TranslationContext);

	const options = getOptions(allowedValues);
	const getSelection = code => options.filter(k => k.code === code);

	const checkIfValid = () => {
		if (answer && answer.valid !== undefined) {
			return answer.valid;
		}
		return answer;
	};
	const valid = checkIfValid();
	const invalid = !checkIfValid();
	const recordOption = newOption => {
		onChange(keyId, { newOption });
		onEditEnd(keyId, { value: newOption });
	};

	const props = [];
	props.renderMenuItemChildren = option => getTranslation(option.label) || option.label;

	return (
		<Form.Group>
			<Form.Label>{getTranslation(name)}</Form.Label>
			{mandatory && <span>*</span>}
			<Typeahead
				{...props}
				id="combo-input"
				defaultSelected={
					answer !== undefined && answer.value ? getSelection(answer.value) : []
				}
				options={options}
				labelKey={option => getTranslation(option.label) || option.label}
				isValid={valid}
				isInvalid={invalid}
				onChange={option => recordOption(option[0] ? option[0].code : null)}
			/>
		</Form.Group>
	);
};

ComboBox.propTypes = {
	allowedValues: PropTypes.shape({
		code: PropTypes.string,
		label: PropTypes.string,
	}).isRequired,
	keyId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	mandatory: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	onEditEnd: PropTypes.func.isRequired,
	answer: PropTypes.shape({
		value: PropTypes.string,
		valid: PropTypes.bool,
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		// eslint-disable-next-line react/forbid-prop-types
		errorValues: PropTypes.any,
	}).isRequired,
};

ComboBox.defaultProps = {
	mandatory: false,
};

export default ComboBox;
