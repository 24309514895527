import React from 'react';
import PropTypes from 'prop-types';
import { FormCheck, FormGroup, FormLabel } from 'react-bootstrap';
import T from '@tunz/translation';

import styles from './Radios.module.scss';
import InfoTip from '../../InfoTip';
import FieldErrorFeedback from '../FieldErrorFeedback';
import SimpleField from '../SimpleField';

class Radios extends SimpleField {
	recordAnswer = event => {
		const { value } = event.target;
		const { keyId, onEditEnd } = this.props;
		onEditEnd(keyId, { value });
	};

	render() {
		const { name, values, direction, answer, mandatory, readonly, tooltip } = this.props;
		const { value, errorMessage, errorCode, errorValues } = answer;
		const valid = this.isValid();
		const invalid = valid === null ? null : !valid;

		return (
			<FormGroup className="mb-3" controlId={name}>
				<FormLabel>
					<T>{name}</T>
					{mandatory && <span>*</span>}
					<InfoTip text={tooltip} />
				</FormLabel>
				{values &&
					values.map(v => (
						<span
							key={v}
							className={
								direction === 'VERTICAL' ? styles.radioSpanV : styles.radioSpanH
							}
						>
							<FormCheck
								key={v}
								name={name}
								type="radio"
								onChange={this.recordAnswer}
								checked={value === v ? 'checked' : ''}
								value={v}
								disabled={readonly}
								lable={v}
								isValid={valid}
								isInvalid={invalid}
							/>
						</span>
					))}
				<FieldErrorFeedback
					errorCode={errorCode}
					defaultValue={errorMessage}
					formatValues={errorValues}
				/>
			</FormGroup>
		);
	}
}

Radios.propTypes = {
	keyId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	values: PropTypes.arrayOf(PropTypes.string).isRequired,
	direction: PropTypes.string,
	mandatory: PropTypes.bool,
	readonly: PropTypes.bool,
	onEditEnd: PropTypes.func,
	answer: PropTypes.shape({
		value: PropTypes.string.isRequired,
		valid: PropTypes.bool,
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		// eslint-disable-next-line react/forbid-prop-types
		errorValues: PropTypes.any,
	}),
	tooltip: PropTypes.string,
};

Radios.defaultProps = {
	direction: 'HORIZONTAL',
	mandatory: false,
	readonly: false,
	onEditEnd: () => undefined,
	answer: { value: '' },
	tooltip: null,
};

export default Radios;
