const buildTmid = id => {
	if (!Number.isSafeInteger(id) || id < 0) {
		console.error('Unable to build tmid from id:', id);
		return undefined;
	}
	const mod97 = 98 - (parseInt(`2922${id}00`, 10) % 97);
	const prefix = mod97 < 10 ? `0${mod97}` : mod97;
	return `TM${prefix}${id}`;
};

export default { buildTmid };
