import _ from 'lodash';
import * as ACTIONS from '../actions/configurationActions';
import MerchatUtils from '../utils/MerchantUtils';

const initialState = {
	isLoading: false,
};
const configurationReducer = (state = initialState, action = { type: '' }) => {
	switch (action.type) {
		case ACTIONS.LOAD_CONFIGURATION:
		case ACTIONS.CREATE_MERCHANT:
			return {
				...state,
				isLoading: true,
				error: null,
			};
		case ACTIONS.CONFIGURATION_LOADED: {
			const merchantId = Number.parseInt(action.payload['merchant.id'], 10);
			const tmid = Number.isNaN(merchantId) ? null : MerchatUtils.buildTmid(merchantId);
			return {
				...state,
				isLoading: false,
				error: null,
				...action.payload,
				'merchant.id': Number.isNaN(merchantId) ? null : merchantId,
				'merchant.tmid': tmid,
			};
		}
		case ACTIONS.CONFIGURATION_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		case ACTIONS.MERCHANT_ID_LOADED:
			return {
				...state,
				isLoading: false,
				error: null,
				'merchant.id': action.merchantId,
			};
		case ACTIONS.TOKEN_EXPIRATION_RESET:
			return {
				...state,
				tokenExpirationWarning: false,
				sessionExpired: false,
			};
		case ACTIONS.TOKEN_EXPIRATION_WARNING:
			return {
				...state,
				tokenExpirationWarning: true,
				sessionExpired: false,
			};
		case ACTIONS.TOKEN_EXPIRATION_EXPIRED:
			return {
				...state,
				tokenExpirationWarning: false,
				sessionExpired: true,
			};
		case ACTIONS.TOKEN_EXPIRATION_TIME_LEFT:
			if (state.tokenExpirationWarning) {
				return {
					...state,
					timeLeftInSeconds: action.timeLeftInSeconds,
				};
			}
			return state;
		case ACTIONS.SET_TMID_VERIFIED: {
			const newState = _.cloneDeep(state);
			newState.isTmidVerified = true;
			return newState;
		}
		default:
			return state;
	}
};

export default configurationReducer;
