import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import T, {
	TranslationProvider,
	TranslationSelector,
	TranslationServerManager,
} from '@tunz/translation';
import { Col, Container, Row } from 'react-bootstrap';
import prettyMs from 'pretty-ms';

import style from './App.module.scss';
import Loading from '../../components/Loading';
import SessionExpired from '../../components/SessionExpired';
import WelcomePage from '../WelcomePage';
import RequirementForm from '../RequirementForm';
import ErrorDisplay from '../../components/ErrorDisplay';
import applicationActions from '../../actions/applicationActions';
import configurationActions from '../../actions/configurationActions';
import BannerWarning from '../../components/BannerWarning';
import QueryString from '../../utils/QueryString';

const defaultLogo = 'https://prod.resources.tunz.com/worldline/logo-h-alt.png';
const baseUrl = window.IFS_ENV ? window.IFS_ENV.BASE_URL || '' : '';
const useDraftTranslations = QueryString.fromCurrentPage().get('useDraftTranslations');
const translationServerManager = new TranslationServerManager(
	'IFSBoarding',
	`${baseUrl}api/translations`,
	useDraftTranslations === 'y' ? 'DRAFT' : undefined,
);

export const App = props => {
	const {
		appStartup,
		isLoading,
		error,
		isMerchantIdPresent,
		isMerchantDataLoaded,
		showTokenWarning,
		tokenExpSecondsLeft,
		onTokenWarningRefresh,
		showSessionExpired,
		onSessionExpiredReload,
		customLogo,
		customCss,
		hideHeader,
		shortName,
	} = props;

	const logo = customLogo || defaultLogo;

	useEffect(appStartup, []);

	let content = <Loading />;
	if (!isLoading || isMerchantDataLoaded) {
		if (error) {
			content = <ErrorDisplay error={error} />;
		} else if (isMerchantIdPresent && isMerchantDataLoaded) {
			content = <RequirementForm />;
		} else {
			content = <WelcomePage />;
		}
	}
	return (
		<TranslationProvider loader={<Loading silent />} manager={translationServerManager}>
			{customCss && <link href={customCss} type="text/css" rel="stylesheet" />}
			<div className={style.App}>
				{!hideHeader && (
					<header className={`${style.AppHeader} application-header`}>
						<Container>
							<Row>
								<Col lg={6}>
									<h1 className={style.AppTitle}>
										<img src={logo} className={style.AppLogo} alt="logo" />
									</h1>
								</Col>
								<Col lg={6} className={style.AppLangSwitcher}>
									<TranslationSelector />
								</Col>
							</Row>
						</Container>
					</header>
				)}
				{showTokenWarning && (
					<Container>
						<Row>
							<Col lg={12}>
								<BannerWarning
									dismissText="REFRESH"
									onDismiss={onTokenWarningRefresh}
								>
									{() => (
										<T
											defaultValue="Your session will expire in {timeLeft}"
											formatValues={{
												timeLeft: prettyMs(tokenExpSecondsLeft * 1000),
											}}
										>
											SESSION_WILL_EXPIRE_IN
										</T>
									)}
								</BannerWarning>
							</Col>
						</Row>
					</Container>
				)}
				{showSessionExpired ? (
					<SessionExpired onSessionExpiredReload={onSessionExpiredReload} />
				) : (
					content
				)}
				<footer className={style.AppFooter}>
					Powered by Worldline - Privacy notice:&nbsp;
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`${process.env.PUBLIC_URL}/privacynotice/contact_en.pdf`}
					>
						Person of contact of {shortName} customer
					</a>{' '}
					-{' '}
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`${process.env.PUBLIC_URL}/privacynotice/ubo_en.pdf`}
					>
						UBO and legal representatives
					</a>
				</footer>
			</div>
		</TranslationProvider>
	);
};

App.propTypes = {
	appStartup: PropTypes.func,
	isLoading: PropTypes.bool,
	isMerchantIdPresent: PropTypes.bool,
	isMerchantDataLoaded: PropTypes.bool,
	showTokenWarning: PropTypes.bool,
	tokenExpSecondsLeft: PropTypes.number,
	onTokenWarningRefresh: PropTypes.func,
	showSessionExpired: PropTypes.bool,
	onSessionExpiredReload: PropTypes.func,
	error: PropTypes.instanceOf(Error),
	customLogo: PropTypes.string,
	customCss: PropTypes.string,
	hideHeader: PropTypes.bool,
	shortName: PropTypes.string,
};

App.defaultProps = {
	appStartup: () => undefined,
	isLoading: false,
	isMerchantIdPresent: false,
	isMerchantDataLoaded: false,
	showTokenWarning: false,
	tokenExpSecondsLeft: 0,
	onTokenWarningRefresh: () => undefined,
	showSessionExpired: false,
	onSessionExpiredReload: () => undefined,
	error: null,
	customLogo: null,
	customCss: null,
	hideHeader: false,
	shortName: null,
};

App.displayName = 'App';

const mapStateToProps = state => ({
	name: state.application.name,
	isLoading: state.config.isLoading || state.application.isLoading,
	isMerchantIdPresent:
		state.config['merchant.id'] !== undefined && state.config['merchant.id'] !== null,
	isMerchantDataLoaded: state.application.loaded,
	error: state.application.error || state.config.error,
	showTokenWarning: state.config.tokenExpirationWarning,
	showSessionExpired: state.config.sessionExpired,
	tokenExpSecondsLeft: state.config.timeLeftInSeconds,
	customLogo: state.config['boarding.logo.url'],
	customCss: state.config['boarding.css.url'],
	hideHeader: state.config.isLoading,
	shortName: state.config['ingenico.name.short'] || 'WFS',
});

const mapDispatchToProps = dispatch => ({
	appStartup: () => {
		dispatch(configurationActions.applicationStartup());
		dispatch(configurationActions.registerTokenExpirationHandling());
	},
	onTokenWarningRefresh: () => dispatch(applicationActions.loadMetadata()),
	onSessionExpiredReload: () => document.location.reload(),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
