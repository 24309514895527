import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Form, FormControl, FormGroup, Row } from 'react-bootstrap';
import cx from 'classnames';
import T from '@tunz/translation';

import styles from './Section.module.scss';
import Fields from '../fields/Fields';
import MetadataUtils from '../../utils/MetadataUtils';
import InfoTip from '../InfoTip';

const GRID_SYSTEM_SIZE = 12;
const FIELDS_PER_LINE = 3;
const FULL_LINE_FIELDS = ['MULTI_SELECTION', 'TEXT'];

class Section extends Component {
	recordAnswerWithSection = (field, value) => {
		const { keyId, recordAnswer } = this.props;
		recordAnswer(keyId, field, value);
	};

	persistAnswerWithSection = (field, value) => {
		const { keyId, persistAnswer } = this.props;
		persistAnswer(keyId, field, value);
	};

	rowsForFields = (fields, answers, sectionName) => {
		const { keyId, pageKey } = this.props;
		const colSize = GRID_SYSTEM_SIZE / FIELDS_PER_LINE;

		const rowsArray = [];
		let rowIndex = 0;

		const mapToField = (f, fullLine) => (
			<Col lg={fullLine ? GRID_SYSTEM_SIZE : colSize} key={f.key}>
				<Fields
					key={f.key}
					keyId={f.key}
					sectionKey={keyId}
					pageKey={pageKey}
					{...f}
					recordAnswer={this.recordAnswerWithSection}
					persistAnswer={this.persistAnswerWithSection}
					answer={answers[f.key]}
				/>
			</Col>
		);

		fields
			.filter(f => f.requirementLevel !== 'HIDDEN')
			.sort(MetadataUtils.positionComparator)
			.forEach(f => {
				const fullLine = FULL_LINE_FIELDS.includes(f.constraint.type);
				if (rowsArray[rowIndex] === undefined) {
					rowsArray[rowIndex] = [];
				}
				if (rowsArray[rowIndex].length >= FIELDS_PER_LINE || fullLine) {
					rowIndex += 1;
					rowsArray[rowIndex] = [];
				}

				rowsArray[rowIndex].push(mapToField(f, fullLine));

				if (fullLine) {
					rowIndex += 1;
					rowsArray[rowIndex] = [];
				}
			});

		// eslint-disable-next-line react/no-array-index-key
		return rowsArray.map((r, i) => <Row key={`${sectionName}_${i}`}>{r}</Row>);
	};

	render() {
		const { name, keyId, fields, answers, error, errorCode, errorValues } = this.props;
		const isInvalid = error !== null;
		return (
			<Container
				fluid
				className={cx(`pl-0 ${name} ${keyId.replace(/ /g, '_')}`, {
					[styles.error]: isInvalid,
				})}
			>
				<Row className={cx(styles.Section)}>
					<Col lg={12}>
						{name && (
							<h3 className="mt-3">
								<T>{name}</T>
								<span className={styles.tooltip}>
									<InfoTip text={`TOOLTIP_SECTION_${name}`} />
								</span>
								{error && <small>{error}</small>}
							</h3>
						)}
						{errorCode && (
							<FormGroup>
								<FormControl.Feedback type="invalid" className="d-block">
									<T defaultValue={error} formatValues={errorValues}>
										{errorCode}
									</T>
								</FormControl.Feedback>
							</FormGroup>
						)}
					</Col>
				</Row>
				<Row>
					<Col lg={12}>
						<Form>
							<Container fluid>{this.rowsForFields(fields, answers, name)}</Container>
						</Form>
					</Col>
				</Row>
			</Container>
		);
	}
}

Section.propTypes = {
	name: PropTypes.string,
	keyId: PropTypes.string.isRequired,
	pageKey: PropTypes.string,
	valid: PropTypes.bool,
	error: PropTypes.string,
	errorCode: PropTypes.number,
	// eslint-disable-next-line react/forbid-prop-types
	errorValues: PropTypes.any,
	fields: PropTypes.arrayOf(Object),
	recordAnswer: PropTypes.func,
	persistAnswer: PropTypes.func,
	answers: PropTypes.shape({
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		// eslint-disable-next-line react/forbid-prop-types
		errorValues: PropTypes.any,
		valid: PropTypes.bool,
	}),
};

Section.defaultProps = {
	name: null,
	pageKey: '',
	answers: {},
	recordAnswer: () => undefined,
	persistAnswer: () => undefined,
	fields: [],
	valid: true,
	error: null,
	errorCode: null,
	errorValues: null,
};

export default Section;
