class TunzError extends Error {
	constructor(code, message, details, values) {
		super(message);
		this.name = 'TunzError';
		this.code = code;
		this.details = details;
		this.values = values;

		// Cleanup stack trace, if available (not supported by all browsers)
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, TunzError);
		}
	}

	static fromValidationError(validationError, values) {
		return new TunzError(validationError.errorCode, validationError.errorMessage, {}, values);
	}
}

export default TunzError;
