import React from 'react';
import PropTypes from 'prop-types';
import T from '@tunz/translation';
import { FaSyncAlt } from 'react-icons/fa';

import style from './Loading.module.scss';

const Loading = ({ silent }) => (
	<div className={style.loading}>
		<FaSyncAlt className={style.loadingImg} />
		{!silent && (
			<h3>
				<T>LOADING_TITLE</T>
			</h3>
		)}
		{!silent && <T>LOADING_MESSAGE</T>}
	</div>
);

Loading.propTypes = {
	silent: PropTypes.bool,
};

Loading.defaultProps = {
	silent: false,
};

export default Loading;
