import React, { useContext } from 'react';

import PropTypes, { arrayOf, string } from 'prop-types';
import { connect } from 'react-redux';

import T, { TranslationContext } from '@tunz/translation';
import style from './KycChecklist.module.scss';

const defineLanguage = (documentObject, pageLanguage) => {
	const language = pageLanguage.toUpperCase().substring(0, 2);
	return Object.keys(documentObject).includes(language) ? language : 'not available';
};

const KycChecklist = ({ kycDocumentsPerLanguage }) => {
	const { currentLanguage } = useContext(TranslationContext);
	const docLanguage = defineLanguage(kycDocumentsPerLanguage, currentLanguage);

	return docLanguage === 'not available' ? null : (
		<div className={style.KycChecklist}>
			<h4 className={style.KycChecklistTitle}>
				<T>HELP_SECTION</T>
			</h4>
			{kycDocumentsPerLanguage[docLanguage] &&
				kycDocumentsPerLanguage[docLanguage].map(item => (
					<a
						key={item.name}
						className={style.KycChecklistLink}
						target="_blank"
						href={item.url}
						rel="noreferrer noopener"
					>
						<T>{item.name}</T>
					</a>
				))}
		</div>
	);
};

KycChecklist.propTypes = {
	kycDocumentsPerLanguage: PropTypes.objectOf(
		arrayOf(
			PropTypes.shape({
				name: string,
				url: string,
			}),
		),
	),
};

KycChecklist.defaultProps = {
	kycDocumentsPerLanguage: {},
};

const mapStateToProps = state => ({
	kycDocumentsPerLanguage: state.application.helpSection.kycDocumentsPerLanguage,
});

export default connect(mapStateToProps)(KycChecklist);
