import QueryString from '../utils/QueryString';
import configApi from '../api/ConfigurationApi';
import * as boardingApi from '../api/BoardingApi';

import appActions from './applicationActions';
import TokenUtil, { ALERTS, WARNING_THRESHOLD } from '../utils/TokenUtil';
import PathUtils from '../utils/PathUtils';

export const LOAD_CONFIGURATION = 'LOAD_CONFIGURATION';
export const CONFIGURATION_LOADED = 'CONFIGURATION_LOADED';
export const CONFIGURATION_ERROR = 'CONFIGURATION_ERROR';
export const MERCHANT_ID_LOADED = 'MERCHANT_ID_LOADED';
export const CREATE_MERCHANT = 'CREATE_MERCHANT';
export const SET_TMID_VERIFIED = 'SET_TMID_VERIFIED';
export const TOKEN_EXPIRATION_RESET = 'TOKEN_EXPIRATION_RESET';
export const TOKEN_EXPIRATION_WARNING = 'TOKEN_EXPIRATION_WARNING';
export const TOKEN_EXPIRATION_EXPIRED = 'TOKEN_EXPIRATION_EXPIRED';
export const TOKEN_EXPIRATION_TIME_LEFT = 'TOKEN_EXPIRATION_TIME_LEFT';
const BRAND_QUERY_PARAM = 'brand';
const OBSOLETE_BRAND = 'ingenico';
const DEFAULT_BRAND = 'worldline';
const COLLECT_CONTEXT = 'COLLECT';

const loadConfigurationAction = () => ({
	type: LOAD_CONFIGURATION,
});

const configError = error => ({
	type: CONFIGURATION_ERROR,
	payload: error,
});

const configurationLoadedAction = data => ({
	type: CONFIGURATION_LOADED,
	payload: data,
});

const createMerchant = () => ({
	type: CREATE_MERCHANT,
});

const merchantIdLoaded = merchantId => ({
	type: MERCHANT_ID_LOADED,
	merchantId,
});

const loadMerchantId = merchantId => dispatch => {
	dispatch(merchantIdLoaded(merchantId));
	dispatch(appActions.loadMetadata());
};

const resetTokenExpiryWarning = () => ({
	type: TOKEN_EXPIRATION_RESET,
});

const setTmidVerified = () => ({
	type: SET_TMID_VERIFIED,
});

const setTokenExpiryWarning = () => ({
	type: TOKEN_EXPIRATION_WARNING,
});

const setTokenExpiryWarningTooLate = () => ({
	type: TOKEN_EXPIRATION_EXPIRED,
});

const setTokenExpiryTimeLeft = timeLeftInSeconds => ({
	type: TOKEN_EXPIRATION_TIME_LEFT,
	timeLeftInSeconds,
});
const getBrand = (pathParts, params) => {
	const brand = pathParts[0] || params.get(BRAND_QUERY_PARAM) || DEFAULT_BRAND;
	return brand === OBSOLETE_BRAND ? DEFAULT_BRAND : brand;
};

const getTmidVerified = () => (dispatch, getState) => {
	if (COLLECT_CONTEXT === getState().answers.context) {
		return getState().config.isTmidVerified;
	}
	return true;
};

const loadConfiguration = () => dispatch => {
	dispatch(loadConfigurationAction());

	const params = QueryString.fromCurrentPage();
	const pathParts = PathUtils.extractPathParts(window.location.pathname);

	const brand = getBrand(pathParts, params);
	params.remove(BRAND_QUERY_PARAM); // Make sure we do not rewrite the brand twice

	const newUrl = QueryString.getUrlWithParameters(params.getAllValues(), `/${brand}/`);
	// eslint-disable-next-line no-restricted-globals
	history.replaceState({}, document.title, newUrl);

	return configApi.getConfiguration(
		brand,
		params.toQueryString(),
		config => {
			dispatch(configurationLoadedAction(config));
			const merchantId = Number.parseInt(config['merchant.id'], 10);
			if (!Number.isNaN(merchantId)) {
				dispatch(loadMerchantId(merchantId));
			}
			return config;
		},
		error => {
			dispatch(configError(error));
			return error;
		},
	);
};

const applicationStartup = () => dispatch => dispatch(loadConfiguration());

const createNewMerchant = () => dispatch => {
	dispatch(createMerchant());

	return boardingApi.createMerchant(
		merchantIdentification => {
			dispatch(setTmidVerified());
			const newUrl = QueryString.getUrlWithParameters({ hash: merchantIdentification.hash });
			// eslint-disable-next-line no-restricted-globals
			history.replaceState({}, document.title, newUrl);
			return dispatch(loadMerchantId(merchantIdentification.id));
		},
		error => dispatch(configError(error)),
	);
};

const registerTokenExpirationHandling = () => dispatch => {
	TokenUtil.attachCB(ALERTS.RESET, () => dispatch(resetTokenExpiryWarning()));
	TokenUtil.attachCB(ALERTS.WARNING, () => dispatch(setTokenExpiryWarning()));
	TokenUtil.attachCB(ALERTS.ON_TIME, () => dispatch(setTokenExpiryWarningTooLate()));
	TokenUtil.attachCB(ALERTS.TIME_LEFT, (alert, remainingSeconds) => {
		if (remainingSeconds < WARNING_THRESHOLD) {
			dispatch(setTokenExpiryTimeLeft(remainingSeconds));
		}
	});
};

export default {
	applicationStartup,
	getTmidVerified,
	loadMerchantId,
	loadConfiguration,
	createNewMerchant,
	registerTokenExpirationHandling,
	resetTokenExpiryWarning,
	setTmidVerified,
};
