import * as Actions from '../actions/applicationActions';
import * as AnswerAction from '../actions/answerActions';
import MetadataUtils from '../utils/MetadataUtils';

const initialState = {
	name: '',
	pages: {},
	loaded: false,
	boardingFlow: {
		steps: [],
		currentStep: 0,
	},
};

const ensurePrefixedUrl = payload => {
	// Check page template urls
	MetadataUtils.onEachPage(
		payload,
		p => p.template && MetadataUtils.fixEndpoint(p.template.endpoint),
	);

	// Check dataGroups urls
	MetadataUtils.onEachDataGroup(payload, d => MetadataUtils.fixEndpoint(d.endpoint));
	MetadataUtils.onEachDataGroup(payload, d => MetadataUtils.fixEndpoint(d.deleteEndpoint));

	// Check sections urls
	MetadataUtils.onEachSection(payload, s => MetadataUtils.fixEndpoint(s.endpoint));

	// Check fields' value urls
	MetadataUtils.onEachField(payload, f => {
		if (f.value && typeof f.value.map === 'function') {
			f.value.forEach(v => {
				if (v.endpoints) {
					Object.values(v.endpoints).forEach(MetadataUtils.fixEndpoint);
				}
			});
		}
	});
	return payload;
};

const generateDataGroupIds = payload => {
	MetadataUtils.onEachPage(payload, page => {
		if (page.dataGroups) {
			page.dataGroups.forEach((dataGroup, i) => {
				let id = `DG_${i * i}`;
				if (dataGroup.groupRef !== undefined) {
					id = dataGroup.groupRef;
				} else if (dataGroup.name !== undefined) {
					id = dataGroup.name;
				}
				// eslint-disable-next-line no-param-reassign
				dataGroup.id = id;
			});
		}
	});
	return payload;
};

const defineFieldsPath = payload => {
	MetadataUtils.onEachField(payload, (field, section, dataGroup, page) => {
		// eslint-disable-next-line no-param-reassign
		field.path = `${page.key}-${dataGroup.id}-${section.key}-${field.name}`;
	});
	return payload;
};

const applicationReducer = (state = initialState, action = { type: '' }) => {
	switch (action.type) {
		case Actions.LOAD_APPLICATION:
			return {
				...state,
				isLoading: true,
			};

		case Actions.APPLICATION_LOADED:
			return {
				...state,
				isLoading: false,
				loaded: true,
				pages: {},
				...defineFieldsPath(ensurePrefixedUrl(generateDataGroupIds(action.payload))),
			};

		case Actions.LOAD_APPLICATION_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};

		case AnswerAction.PERSIST_ANSWER:
			return {
				...state,
				isSaving: true,
			};

		case AnswerAction.PERSIST_ANSWER_COMPLETE:
		case AnswerAction.PERSIST_ANSWER_ERROR:
		case AnswerAction.ANSWER_VALIDATION_ERROR:
			return {
				...state,
				isSaving: false,
			};
		default:
			return state;
	}
};

export default applicationReducer;
