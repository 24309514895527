import React, { Component } from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';
import T from '@tunz/translation';
import mime from 'mime';

import FileItem from './FileItem';
import style from './UploadFileManager.module.scss';
import {
	fileAlreadyAddedError,
	fileNumberExceededError,
	fileSizeExceededError,
	fileTypeNotAcceptedError,
	fileUnreadableError,
} from './UploadFileError';
import { saveData as performQuery } from '../../api/BoardingApi';
import { displayFile } from '../../utils/FileViewerUtils';

class UploadFileManager extends Component {
	constructor(props) {
		super(props);

		this.state = { errors: [] };

		// Disallow file drag&drop on the window
		window.addEventListener(
			'dragover',
			e => {
				e.preventDefault();
			},
			false,
		);
		window.addEventListener(
			'drop',
			e => {
				e.preventDefault();
			},
			false,
		);
	}

	onDrop = ev => {
		// Prevent default behavior (Prevent file from being opened)
		ev.stopPropagation();
		ev.preventDefault();

		return this.loadFiles(Object.values(ev.dataTransfer.files));
	};

	getFilesFromChildren = () => React.Children.map(this.props.children, child => child.props.file);

	checkFile = file => {
		const { maxFileSize, acceptedFileTypes } = this.props;

		if (file.size > maxFileSize) {
			return fileSizeExceededError(file.name, filesize(maxFileSize));
		}
		if (!acceptedFileTypes.includes(file.type)) {
			return fileTypeNotAcceptedError(file.name, acceptedFileTypes.join(', '));
		}
		const currentFiles = this.getFilesFromChildren();
		if (currentFiles.filter(f => f.name === file.name).length > 0) {
			return fileAlreadyAddedError(file.name);
		}
		return null;
	};

	loadFiles = filesToLoad => {
		const { maxNumberOfFiles, onChange, children } = this.props;
		if (React.Children.count(children) + filesToLoad.length > maxNumberOfFiles) {
			this.addError(fileNumberExceededError(maxNumberOfFiles));
			return Promise.resolve();
		}
		return Promise.all(filesToLoad.map(this.loadFile))
			.then(loadedFiles => {
				const allFiles = [...this.getFilesFromChildren(), ...loadedFiles];
				onChange(allFiles);
			})
			.catch(this.addError);
	};

	loadFile = file =>
		new Promise((resolve, reject) => {
			const validationError = this.checkFile(file);
			if (validationError) {
				reject(validationError);
			} else {
				const reader = new FileReader();
				reader.onloadend = () => {
					const newFile = {
						filename: file.name,
						type: file.type,
						data: reader.result.replace('data:', '').replace(/^.+,/, ''),
					};
					resolve(newFile);
				};
				reader.onerror = () => {
					reader.abort();
					reject(fileUnreadableError(file.name));
				};
				reader.readAsDataURL(file);
			}
		});

	addError = error => {
		const { errors } = this.state;
		const { errorDisplayTimeout } = this.props;
		this.setState({ errors: [...errors, error] });
		setTimeout(() => this.removeError(error.id), errorDisplayTimeout);
	};

	removeError = id => {
		const { errors } = this.state;
		this.setState({ errors: errors.filter(e => e.id !== id) });
	};

	clearInput = () => {
		if (this.input != null) {
			// if not yet unmounted
			this.input.value = '';
		}
	};

	filesAdded = e => this.loadFiles(Object.values(e.target.files)).then(this.clearInput);

	browseFile = event => {
		event.preventDefault();
		this.input.click();
	};

	removeFile = async fileName => {
		const files = this.getFilesFromChildren().filter(f => f.filename === fileName);
		const file = files.length > 0 ? files[0] : {};
		if (file.endpoints) {
			await this.props.sendData(file.endpoints.DELETE, {});
		}
		this.props.onChange(this.getFilesFromChildren().filter(f => f.filename !== fileName));
	};

	retrieveFile = async fileName => {
		const files = this.getFilesFromChildren().filter(f => f.filename === fileName);
		const file = files.length > 0 ? files[0] : {};
		if (file.id) {
			// The file comes from the server, use the dedicated endpoint
			const remoteFileData = await performQuery(file.endpoints.GET, null);
			const type = mime.getType(remoteFileData.filename);
			displayFile(remoteFileData.content, type);
		} else {
			// The file content is still in the page, get it right away
			displayFile(file.data, file.type);
		}
	};

	render = () => {
		const { maxNumberOfFiles, acceptedFileTypes, children } = this.props;
		const { errors } = this.state;
		const maxFilesReached = React.Children.count(children) >= maxNumberOfFiles;
		return (
			<div
				id="drop_zone"
				onDrop={!maxFilesReached ? this.onDrop : undefined}
				className={style.dropZone}
			>
				{!maxFilesReached ? (
					<div className={style.dropLabel}>
						<span>
							<T>DROP_FILE</T>
						</span>
						<button type="button" onClick={this.browseFile}>
							<T>BROWSE_FILE</T>
						</button>
					</div>
				) : null}
				<span className={style.hidden}>
					<input
						multiple
						type="file"
						onChange={this.filesAdded}
						accept={acceptedFileTypes.join(',')}
						ref={r => {
							this.input = r;
						}}
					/>
				</span>
				<ul className={style.fileItemList}>
					{React.Children.map(children, e =>
						React.cloneElement(e, {
							removeFile: this.removeFile,
							retrieveFile: this.retrieveFile,
						}),
					)}
				</ul>
				<ul className={style.errorList}>
					{errors.map(e => (
						<li key={e.id}>
							<T defaultValue={e.message} formatValues={e.values}>
								{e.code}
							</T>
						</li>
					))}
				</ul>
			</div>
		);
	};
}

UploadFileManager.propTypes = {
	maxNumberOfFiles: PropTypes.number,
	maxFileSize: PropTypes.number,
	acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
	errorDisplayTimeout: PropTypes.number,
	onChange: PropTypes.func,
	sendData: PropTypes.func,
	children: PropTypes.oneOfType([
		PropTypes.shape({
			type: PropTypes.oneOf([FileItem]),
		}),
		PropTypes.arrayOf(
			PropTypes.shape({
				type: PropTypes.oneOf([FileItem]),
			}),
		),
	]),
};

UploadFileManager.defaultProps = {
	maxNumberOfFiles: 1,
	maxFileSize: 15 * 1024 * 1024, // 15Mb
	acceptedFileTypes: [],
	errorDisplayTimeout: 5000,
	onChange: () => undefined,
	sendData: () => undefined,
	children: [],
};

export default UploadFileManager;
