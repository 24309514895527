import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, FormLabel, FormControl, Form, InputGroup } from 'react-bootstrap';
import { FaSyncAlt } from 'react-icons/fa';
import T from '@tunz/translation';
import shortid from 'shortid';
import styles from './MultiText.module.scss';
import InfoTip from '../../InfoTip';
import FieldErrorFeedback from '../FieldErrorFeedback';

class MultiText extends PureComponent {
	static generateValue = (value = '') => ({
		key: shortid.generate(),
		value,
	});

	onChange = key => event => {
		const { value } = event.target;
		const { answer } = this.props;
		const updated = answer.map(v =>
			key === v.key
				? {
						...v,
						value,
				  }
				: v,
		);
		this.saveChange(updated);
	};

	onBlur = () => {
		const { keyId, onEditEnd, answer } = this.props;
		onEditEnd(keyId, answer);
	};

	onClick = (index, command) => {
		const { answer } = this.props;
		let updated;
		switch (command) {
			case '+':
				updated = answer.slice(0);
				updated.splice(index + 1, 0, MultiText.generateValue());
				break;

			case '-':
				updated = answer.filter(a => a.key !== answer[index].key);
				break;

			default:
				throw new Error(`Unknown command: ${command}`);
		}
		this.saveChange(updated);
	};

	saveChange(updated) {
		const { keyId, recordAnswer } = this.props;
		recordAnswer(keyId, updated);
	}

	render() {
		const {
			name,
			keyId,
			min,
			max,
			mandatory,
			answer,
			placeholder,
			tooltip,
			readonly,
		} = this.props;
		return (
			<Form.Group className="mb-3" controlId={name}>
				<FormLabel>
					<T>{name}</T>
					{mandatory && <span>*</span>}
					<InfoTip text={tooltip} />
				</FormLabel>
				{answer.map((v, i) => {
					const btnAdd = (
						<Button onClick={() => this.onClick(i, '+')} disabled={readonly}>
							<FaSyncAlt className={styles.plusButton} glyph="plus-sign" />
						</Button>
					);
					const btnDelete =
						answer.length > 1 ? (
							<Button onClick={() => this.onClick(i, '-')} disabled={readonly}>
								<FaSyncAlt className={styles.minusButton} glyph="minus-sign" />
							</Button>
						) : null;
					const { key, value, errorMessage, errorCode, errorValues } = v;

					return (
						<Form.Group className="mb-3" controlId={`${keyId}-${i}`} key={key}>
							<InputGroup>
								<FormControl
									type="text"
									placeholder={placeholder}
									onChange={this.onChange(key)}
									onBlur={this.onBlur}
									value={value}
									minLength={min}
									maxLength={max}
									disabled={readonly}
								/>
								{btnDelete}
								{btnAdd}
							</InputGroup>
							<FieldErrorFeedback
								errorCode={errorCode}
								defaultValue={errorMessage}
								formatValues={errorValues}
							/>
						</Form.Group>
					);
				})}
			</Form.Group>
		);
	}
}

MultiText.propTypes = {
	keyId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	min: PropTypes.number,
	max: PropTypes.number,
	answer: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			valid: PropTypes.bool,
			errorMessage: PropTypes.string,
			errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

			// eslint-disable-next-line react/forbid-prop-types
			errorValues: PropTypes.any,
		}),
	),
	recordAnswer: PropTypes.func,
	onEditEnd: PropTypes.func,
	mandatory: PropTypes.bool,
	placeholder: PropTypes.string,
	tooltip: PropTypes.string,
	readonly: PropTypes.bool,
};

MultiText.defaultProps = {
	min: 0,
	max: 10000,
	mandatory: false,
	recordAnswer: () => undefined,
	onEditEnd: () => undefined,
	answer: [MultiText.generateValue()],
	placeholder: '',
	tooltip: null,
	readonly: false,
};

export default MultiText;
