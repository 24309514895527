import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import T, { TranslationContext } from '@tunz/translation';

import InfoTip from '../../InfoTip';
import FieldErrorFeedback from '../FieldErrorFeedback';
import SimpleField from '../SimpleField';

class MultiValue extends SimpleField {
	endEditing = event => {
		const { value } = event.target;
		const { keyId, onEditEnd } = this.props;
		onEditEnd(keyId, { value });
	};

	getItems = getTranslation => {
		const { values, name, translationPrefix } = this.props;
		let itemsMap = new Map();

		const defaultOption = (
			<option key={`${name}_SELECT_VALUE`} value="" hidden disabled>
				{getTranslation('PLEASE_SELECT_VALUE') || '-'}
			</option>
		);

		Object.entries(values).forEach(([k, v]) => {
			const text = getTranslation(`IFS_BOARDING_${translationPrefix}${v}`) || v;

			itemsMap.set(
				text.trim().length > 0 ? text : v, // In case of translations being ' '
				<option key={k} value={k} className={k}>
					{text}
				</option>,
			);
		});

		itemsMap = new Map([...itemsMap.entries()].sort());
		return [defaultOption, ...itemsMap.values()];
	};

	render() {
		const { name, answer, mandatory, readonly, tooltip } = this.props;
		const { value, errorMessage, errorCode, errorValues } = answer;

		const valid = this.isValid();
		const invalid = valid === null ? null : !valid;

		return (
			<TranslationContext.Consumer>
				{({ getTranslation }) => (
					<FormGroup className="mb-3" controlId={name}>
						<FormLabel>
							<span>
								<T>{name}</T>
							</span>
							{mandatory && <span>*</span>}
							<InfoTip text={tooltip} />
						</FormLabel>
						<FormControl
							as="select"
							placeholder="select"
							name={name}
							onChange={this.endEditing}
							value={value}
							disabled={readonly}
							isValid={valid}
							isInvalid={invalid}
						>
							{this.getItems(getTranslation)}
						</FormControl>
						<FieldErrorFeedback
							errorCode={errorCode}
							defaultValue={errorMessage}
							formatValues={errorValues}
						/>
						<FormControl.Feedback type="invalid" />
					</FormGroup>
				)}
			</TranslationContext.Consumer>
		);
	}
}

MultiValue.propTypes = {
	keyId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	values: PropTypes.objectOf(PropTypes.string).isRequired,
	translationPrefix: PropTypes.string,
	mandatory: PropTypes.bool,
	readonly: PropTypes.bool,
	onEditEnd: PropTypes.func,
	answer: PropTypes.shape({
		value: PropTypes.string.isRequired,
		valid: PropTypes.bool,
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		// eslint-disable-next-line react/forbid-prop-types
		errorValues: PropTypes.any,
	}),
	tooltip: PropTypes.string,
};

MultiValue.defaultProps = {
	translationPrefix: '',
	mandatory: false,
	readonly: false,
	onEditEnd: () => undefined,
	answer: { value: '' },
	tooltip: null,
};

export default MultiValue;
