import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import T from '@tunz/translation';

const BannerWarning = ({ children, dismissText, onDismiss }) => (
	<Alert variant="warning" className="mt-2">
		<Container fluid>
			<Row>
				<Col lg={8}>{children()}</Col>
				<Col lg={4}>
					<Button variant="warning" onClick={onDismiss}>
						<T>{dismissText}</T>
					</Button>
				</Col>
			</Row>
		</Container>
	</Alert>
);

BannerWarning.propTypes = {
	children: PropTypes.func,
	dismissText: PropTypes.string,
	onDismiss: PropTypes.func,
};

BannerWarning.defaultProps = {
	children: () => <></>,
	dismissText: 'DISMISS',
	onDismiss: () => undefined,
};

export default BannerWarning;
