const API_PREFIX = '/api/boardingAPI';

const fixEndpoint = endpoint => {
	if (endpoint && endpoint.url && !endpoint.url.includes(API_PREFIX)) {
		// eslint-disable-next-line no-param-reassign
		endpoint.url = API_PREFIX + endpoint.url;
	}
};

const onEachPage = (application, callback) => {
	if (application && application.pages) {
		Object.values(application.pages).forEach(callback);
	}
};

const onEachDataGroup = (application, callback) => {
	onEachPage(application, page => {
		if (page.dataGroups) {
			page.dataGroups.forEach(dataGroup => callback(dataGroup, page));
		}
	});
};

const onEachSection = (application, callback) => {
	onEachDataGroup(application, (dataGroup, page) => {
		if (dataGroup.sections) {
			dataGroup.sections.forEach(section => callback(section, dataGroup, page));
		}
	});
};

const onEachField = (application, callback) => {
	onEachSection(application, (section, dataGroup, page) => {
		if (section.fields) {
			section.fields.forEach(field => callback(field, section, dataGroup, page));
		}
	});
};

const positionComparator = (first, second) => {
	if (first.position === undefined && second.position === undefined) {
		return 0;
	}
	if (first.position !== undefined && second.position === undefined) {
		return 1;
	}
	if (first.position === undefined && second.position !== undefined) {
		return -1;
	}
	return first.position - second.position;
};

export default {
	fixEndpoint,
	onEachPage,
	onEachDataGroup,
	onEachSection,
	onEachField,
	positionComparator,
};
