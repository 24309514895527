import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'react-bootstrap';

import Disclaimer, { disclaimerType } from './Disclaimer';
import styles from './DisclaimerList.module.scss';

function DisclaimerList({ disclaimers }) {
	if (!disclaimers) {
		return null;
	}
	return (
		<Container fluid>
			<Row>
				<Col md={12}>
					<ul className={`disclaimer-list ${styles.disclaimerList}`}>
						{disclaimers.map(d => (
							<Disclaimer {...d} key={d.translationCode} />
						))}
					</ul>
				</Col>
			</Row>
		</Container>
	);
}

DisclaimerList.propTypes = {
	disclaimers: PropTypes.arrayOf(disclaimerType),
};

DisclaimerList.defaultProps = {
	disclaimers: undefined,
};

export default DisclaimerList;
