import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import T, { TranslationContext } from '@tunz/translation';
import style from './Label.module.scss';

import InfoTip from '../../InfoTip';

const Label = ({ keyId, name, answer }) => {
	const { getTranslation } = useContext(TranslationContext);
	const tooltip = `TOOLTIP_${answer.value}`;
	return (
		<FormGroup className="mb-3" controlId={keyId}>
			<FormLabel>
				<T>{name}</T>
				<InfoTip text={tooltip} />
			</FormLabel>
			<FormControl
				className={style.label}
				as="textarea"
				type="text"
				name={name}
				value={getTranslation(answer.value) || answer.value}
				readOnly
				plaintext
			/>
		</FormGroup>
	);
};

Label.propTypes = {
	keyId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	answer: PropTypes.shape({
		value: PropTypes.node.isRequired,
	}).isRequired,
};

export default Label;
