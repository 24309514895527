import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import T from '@tunz/translation';

import InfoTip from '../../InfoTip';
import FieldErrorFeedback from '../FieldErrorFeedback';

class RegexBounded extends PureComponent {
	recordUserAnswer = event => {
		const { value } = event.target;
		const { keyId, onChange } = this.props;
		onChange(keyId, { value });
	};

	endTheEdition = () => {
		const { keyId, onEditEnd, answer } = this.props;
		onEditEnd(keyId, answer);
	};

	checkIfValid() {
		const { answer } = this.props;
		if (answer && answer.valid !== undefined) {
			return answer.valid;
		}
		return null;
	}

	render() {
		const { name, keyId, answer, mandatory, readonly, placeholder, tooltip } = this.props;
		const { value, errorMessage, errorCode, errorValues } = answer;

		const valid = this.checkIfValid();
		const invalid = valid === null ? null : !valid;

		return (
			<FormGroup>
				<FormLabel>
					<T>{name}</T>
					{mandatory && <span>*</span>}
					<InfoTip text={tooltip} />
				</FormLabel>
				<FormControl
					type="regex"
					id={keyId}
					onBlur={this.endTheEdition}
					placeholder={placeholder}
					value={value}
					disabled={readonly}
					isValid={valid}
					isInvalid={invalid}
					onChange={this.recordUserAnswer}
					onKeyUp={this.checkValidity}
				/>
				<FieldErrorFeedback
					errorCode={errorCode}
					defaultValue={errorMessage}
					formatValues={errorValues}
				/>
			</FormGroup>
		);
	}
}

RegexBounded.propTypes = {
	keyId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	mandatory: PropTypes.bool,
	readonly: PropTypes.bool,
	onChange: PropTypes.func,
	onEditEnd: PropTypes.func,
	answer: PropTypes.shape({
		value: PropTypes.string.isRequired,
		valid: PropTypes.bool,
		errorMessage: PropTypes.string,
		errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		// eslint-disable-next-line react/forbid-prop-types
		errorValues: PropTypes.any,
	}),
	placeholder: PropTypes.string,
	tooltip: PropTypes.string,
};

RegexBounded.defaultProps = {
	mandatory: false,
	readonly: false,
	onChange: () => undefined,
	onEditEnd: () => undefined,
	answer: { value: '' },
	placeholder: '',
	tooltip: null,
};

export default RegexBounded;
