import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import answerActions from '../../actions/answerActions';
import SimplePage from './SimplePage';
import RepeatableDatagroupPage from './RepeatableDatagroupPage';
import { dataGroupTemplate } from '../DataGroup';
import DisclaimerList, { disclaimerType } from '../DisclaimerList';

export class Page extends Component {
	recordAnswerForPage = (dataGroup, section, field, value) => {
		const { keyId, recordAnswer } = this.props;
		recordAnswer(keyId, dataGroup, section, field, value);
	};

	persistAnswerForPage = (dataGroup, section, field, value) => {
		const { keyId, persistAnswer } = this.props;
		persistAnswer(keyId, dataGroup, section, field, value);
	};

	render() {
		const { repeatableDatagroupInPage, topDisclaimers, bottomDisclaimers } = this.props;
		return (
			<>
				<DisclaimerList disclaimers={topDisclaimers} />
				{repeatableDatagroupInPage ? (
					<RepeatableDatagroupPage
						{...this.props}
						recordAnswer={this.recordAnswerForPage}
						persistAnswer={this.persistAnswerForPage}
					/>
				) : (
					<SimplePage
						{...this.props}
						recordAnswer={this.recordAnswerForPage}
						persistAnswer={this.persistAnswerForPage}
					/>
				)}
				<DisclaimerList disclaimers={bottomDisclaimers} />
			</>
		);
	}
}

Page.propTypes = {
	name: PropTypes.string.isRequired,
	keyId: PropTypes.string.isRequired,
	valid: PropTypes.bool,
	error: PropTypes.string,
	errorCode: PropTypes.number,
	dataGroups: PropTypes.arrayOf(dataGroupTemplate),
	template: dataGroupTemplate,
	recordAnswer: PropTypes.func,
	persistAnswer: PropTypes.func,
	answers: PropTypes.shape({}),
	isSaving: PropTypes.bool,
	repeatableDatagroupInPage: PropTypes.bool,
	addElement: PropTypes.func,
	removeElement: PropTypes.func,
	reportError: PropTypes.func,
	topDisclaimers: PropTypes.arrayOf(disclaimerType),
	bottomDisclaimers: PropTypes.arrayOf(disclaimerType),
};

Page.defaultProps = {
	dataGroups: [],
	template: {
		endpoint: {
			method: '',
			url: '',
		},
		sections: [],
	},
	answers: {},
	recordAnswer: () => undefined,
	persistAnswer: () => undefined,
	isSaving: false,
	valid: true,
	error: null,
	errorCode: null,
	repeatableDatagroupInPage: false,
	addElement: () => undefined,
	removeElement: () => undefined,
	reportError: () => undefined,
	topDisclaimers: [],
	bottomDisclaimers: [],
};

const mapStateToProps = (state, ownProps) => ({
	isSaving: state.application.isSaving || state.application.isLoading,
	answers: state.answers[ownProps.name],
});
const mapDispatchToPropsFactory = dispatch => ({
	recordAnswer: (page, dataGroup, section, field, value) =>
		dispatch(answerActions.recordAnswer(page, dataGroup, section, field, value)),
	persistAnswer: (page, dataGroup, section, field, value) =>
		dispatch(answerActions.persistAnswer(page, dataGroup, section, field, value)),
	addElement: endpoint => dispatch(answerActions.sendData(endpoint)),
	removeElement: endpoint => dispatch(answerActions.sendData(endpoint)),
	reportError: error => dispatch(answerActions.reportError(error)),
});

export default connect(mapStateToProps, mapDispatchToPropsFactory)(Page);
