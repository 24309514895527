const { location } = window;

class QueryString {
	dec = decodeURIComponent;

	enc = encodeURIComponent;

	urlSearchParams;

	static fromCurrentPage() {
		return new QueryString(location.search);
	}

	static getUrlWithParameters(newParams, pathname = location.pathname) {
		const qs = QueryString.fromCurrentPage();
		const { origin } = location;
		qs.addAll(newParams);
		return `${origin}${pathname}${qs.toQueryString()}`;
	}

	constructor(locationSearch) {
		if (locationSearch === undefined || locationSearch.length === 0) {
			this.urlSearchParams = new URLSearchParams();
			return;
		}
		if (!locationSearch.startsWith('?')) {
			throw new Error('constructor param must be window.location.search');
		}

		this.urlSearchParams = new URLSearchParams(locationSearch);
	}

	addAll(obj) {
		Object.entries(obj).forEach(([key, value]) => {
			this.urlSearchParams.set(key, value);
		});
	}

	has(paramName) {
		return this.urlSearchParams.has(paramName);
	}

	get(paramName) {
		return this.urlSearchParams.get(paramName);
	}

	getAllValues() {
		const values = {};
		this.urlSearchParams.forEach((value, key) => {
			values[key] = value;
		});
		return values;
	}

	remove(paramName) {
		const existing = this.urlSearchParams.has(paramName);
		this.urlSearchParams.delete(paramName);
		return existing;
	}

	toQueryString() {
		return `?${this.urlSearchParams.toString()}`;
	}
}

export default QueryString;
